import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { NSLoader } from '@natsary/ns-fusion';

const EditCoupon = () => {
  const { couponId } = useParams();
  const [coupon, setCoupon] = useState(null);
  const [loading, setLoading] = useState(true);
  const [changes, setChanges] = useState(false);

  useEffect(() => {
    fetchCouponDetails();
  }, []);

  const fetchCouponDetails = async () => {
    const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;

    try {
      const response = await fetch(`${BASE_API_URL}/natsary-admin/coupons/${couponId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
        },
      });
      if (response.ok) {
        const { data } = await response.json();
        // Exclude fields not required and __v
        const { _id, createdAt, updatedAt, userId, createdBy, __v, isGeneral, ...couponData } =
          data;
        // Convert expiry date to YYYY-MM-DD format
        couponData.expiryDate = new Date(couponData.expiryDate).toISOString().split('T')[0];
        setCoupon(couponData);
        setLoading(false);
      } else {
        console.error('Failed to fetch coupon details');
      }
    } catch (error) {
      console.error('Error fetching coupon details:', error);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;

      const response = await fetch(`${BASE_API_URL}/natsary-admin/coupons/${couponId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
        },
        body: JSON.stringify(coupon),
      });
      if (response.ok) {
        alert('Coupon details updated successfully!');
        setChanges(false);
      } else {
        alert('Failed to update coupon details.');
      }
    } catch (error) {
      console.error('Error saving coupon details:', error);
    }
  };

  if (loading) {
    return <NSLoader isVisible />;
  }

  return (
    <div className='w-full p-4 bg-gray-100 min-h-screen flex flex-col items-center'>
      <div className='w-full max-w-2xl p-6 bg-white rounded-lg shadow-lg'>
        <h2 className='text-3xl font-semibold mb-8 text-gray-800'>Edit Coupon</h2>
        <form onSubmit={handleSave}>
          {Object.keys(coupon).map(
            (key) =>
              key !== 'createdAt' &&
              key !== 'updatedAt' &&
              key !== 'userId' &&
              key !== 'createdBy' &&
              key !== '__v' &&
              key !== 'isGeneral' && (
                <div key={key} className='mb-4'>
                  {key === 'expiryDate' ? (
                    <div>
                      <label htmlFor={key} className='block text-gray-700 text-sm font-bold mb-2'>
                        Expiry Date
                      </label>
                      <input
                        type='date'
                        id={key}
                        name={key}
                        value={coupon[key]}
                        onChange={(e) => {
                          setCoupon({ ...coupon, [key]: e.target.value });
                          setChanges(true);
                        }}
                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      />
                    </div>
                  ) : key === 'active' ||
                    key === 'multipleUse' ||
                    key === 'singleUserMultipleUse' ? (
                    <div className='flex items-center'>
                      <input
                        type='checkbox'
                        id={key}
                        name={key}
                        checked={coupon[key]}
                        onChange={(e) => {
                          setCoupon({ ...coupon, [key]: e.target.checked });
                          setChanges(true);
                        }}
                        className='form-checkbox h-5 w-5 text-blue-600'
                      />
                      <label htmlFor={key} className='ml-2 block text-gray-700 text-sm font-bold'>
                        {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                      </label>
                    </div>
                  ) : key === 'usedUsers' ? (
                    <div>
                      <label htmlFor={key} className='block text-gray-700 text-sm font-bold mb-2'>
                        Used Users
                      </label>
                      {coupon[key].map((val) => {
                        console.log(val);
                        return (
                          <Link
                            className='text-blue-600 underline'
                            to={`/users/${val._id}`}
                          >{`${val.firstName} ${val.lastName}`}</Link>
                        );
                      })}
                    </div>
                  ) : (
                    <div>
                      <label htmlFor={key} className='block text-gray-700 text-sm font-bold mb-2'>
                        {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                      </label>
                      <input
                        type='text'
                        id={key}
                        name={key}
                        value={coupon[key]}
                        onChange={(e) => {
                          setCoupon({ ...coupon, [key]: e.target.value });
                          setChanges(true);
                        }}
                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      />
                    </div>
                  )}
                </div>
              ),
          )}

          <button
            type='submit'
            className={`mt-8 w-full py-3 rounded-md text-white ${
              changes ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-300 cursor-not-allowed'
            }`}
            disabled={!changes}
          >
            Save Changes
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditCoupon;
