import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import AddCoupon from '../components/AddCoupon';

function Coupons() {
  const [coupons, setCoupons] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [showCouponModal, setShowCouponModal] = useState(false);

  useEffect(() => {
    fetchCoupons();
  }, [page]);

  const fetchCoupons = async () => {
    const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;

    const response = await fetch(`${BASE_API_URL}/natsary-admin/coupons?page=${page}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
      },
    });
    if (response.ok) {
      const { data } = await response.json();
      //   setTotalItems(data);
      setCoupons(data);
    }
  };

  const refreshData = () => {
    fetchCoupons();
  };

  const addCouponModalSetting = () => {
    setShowCouponModal(!showCouponModal);
  };

  return (
    <div className='w-full flex justify-center h-screen'>
      <div className='flex flex-col gap-5 w-11/12'>
        <div className=''>
          <div className='flex justify-between items-center pb-5'>
            <span className='font-semibold px-4'>Coupons</span>
            <button
              className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
              onClick={addCouponModalSetting}
            >
              Add Coupon
            </button>
          </div>
          <div className='overflow-x-auto border p-6 bg-white rounded-lg'>
            <table className='min-w-full divide-y-2 divide-gray-200 text-sm'>
              <thead>
                <tr>
                  <th className='whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900'>
                    Coupon Code
                  </th>
                  <th className='whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900'>
                    Expiry Date (dmy)
                  </th>
                  <th className='whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900'>
                    Active
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200'>
                {coupons.map((coupon) => (
                  <tr key={coupon._id}>
                    <td className='whitespace-nowrap px-4 py-2 text-gray-900'>
                      <Link className='text-blue-600 underline' to={`/coupons/${coupon._id}`}>
                        {coupon.code}
                      </Link>
                    </td>
                    <td className='whitespace-nowrap px-4 py-2 text-gray-700'>{`${('0' + new Date(coupon.expiryDate).getDate()).slice(-2)}-${('0' + (new Date(coupon.expiryDate).getMonth() + 1)).slice(-2)}-${new Date(coupon.expiryDate).getFullYear()}`}</td>
                    <td className='whitespace-nowrap px-4 py-2 text-gray-700'>
                      {coupon.active ? 'Yes' : 'No'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {showCouponModal && (
        <AddCoupon addCouponModalSetting={addCouponModalSetting} refreshData={refreshData} />
      )}
    </div>
  );
}

export default Coupons;
