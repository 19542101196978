import { NSLoader } from '@natsary/ns-fusion';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

const EditProductCategory = () => {
  const { categoryId } = useParams();
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [changes, setChanges] = useState(false);
  const [productTypes, setProductTypes] = useState([]);

  useEffect(() => {
    fetchCategoryDetails();
    fetchProductTypes();
  }, []);

  const fetchCategoryDetails = async () => {
    const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;

    try {
      const response = await fetch(
        `${BASE_API_URL}/natsary-admin/productCategory/${categoryId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
          },
        },
      );
      const { data } = await response.json();
      setCategory(data.productCategory);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching category details:', error);
    }
  };

  const fetchProductTypes = async () => {
    const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;

    try {
      const response = await fetch(`${BASE_API_URL}/natsary-admin/productType`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
        },
      });
      const { data } = await response.json();
      setProductTypes(data.productTypes);
    } catch (error) {
      console.error('Error fetching product types:', error);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;

      const response = await fetch(
        `${BASE_API_URL}/natsary-admin/productCategory/update/${categoryId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
          },
          body: JSON.stringify({
            name: category.name,
            code: category.code,
            description: category.description,
            gst: category.gst,
            productType: category.productType._id,
            active: category.active,
          }),
        },
      );
      if (response.ok) {
        alert('Category details updated successfully!');
        setChanges(false);
      } else {
        alert('Failed to update category details.');
      }
    } catch (error) {
      console.error('Error saving category details:', error);
    }
  };

  if (loading) {
    return <NSLoader isVisible />;
  }

  return (
    <div className='w-full p-4 bg-gray-100 min-h-screen flex flex-col items-center'>
      <div className='w-full overflow-x-auto p-6 bg-white rounded-lg shadow-lg'>
        <h2 className='text-3xl font-semibold mb-8 text-gray-800'>Edit Product Category</h2>
        <form onSubmit={handleSave}>
          <div className='mb-4'>
            <label htmlFor='name' className='block text-gray-700 text-sm font-bold mb-2'>
              Category Name
            </label>
            <input
              type='text'
              id='name'
              name='name'
              value={category.name}
              onChange={(e) => {
                setCategory({ ...category, name: e.target.value });
                setChanges(true);
              }}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            />
          </div>

          <div className='mb-4'>
            <label htmlFor='code' className='block text-gray-700 text-sm font-bold mb-2'>
              Category Code
            </label>
            <input
              type='text'
              id='code'
              name='code'
              value={category.code}
              onChange={(e) => {
                setCategory({ ...category, code: e.target.value });
                setChanges(true);
              }}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            />
          </div>

          <div className='mb-4'>
            <label htmlFor='description' className='block text-gray-700 text-sm font-bold mb-2'>
              Description
            </label>
            <textarea
              id='description'
              name='description'
              value={category.description}
              onChange={(e) => {
                setCategory({ ...category, description: e.target.value });
                setChanges(true);
              }}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            />
          </div>

          <div className='mb-4'>
            <label htmlFor='gst' className='block text-gray-700 text-sm font-bold mb-2'>
              GST
            </label>
            <input
              type='number'
              id='gst'
              name='gst'
              value={category.gst}
              onChange={(e) => {
                setCategory({ ...category, gst: e.target.value });
                setChanges(true);
              }}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            />
          </div>

          <div className='mb-4'>
            <label htmlFor='productType' className='block text-gray-700 text-sm font-bold mb-2'>
              Product Type
            </label>
            <select
              id='productType'
              name='productType'
              value={category.productType}
              onChange={(e) => {
                setCategory({ ...category, productType: e.target.value });
                setChanges(true);
              }}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            >
              <option value=''>Select Product Type</option>
              {productTypes.map((type) => (
                <option key={type._id} value={type._id}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>

          <div className='mb-4'>
            <label htmlFor='active' className='block text-gray-700 text-sm font-bold mb-2'>
              Active
            </label>
            <input
              type='checkbox'
              id='active'
              name='active'
              checked={category.active}
              onChange={(e) => {
                setCategory({ ...category, active: e.target.checked });
                setChanges(true);
              }}
              className='h-6 w-6 text-blue-600'
            />
          </div>

          <button
            type='submit'
            className={`mt-8 w-full py-3 rounded-md text-white ${
              changes ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-300 cursor-not-allowed'
            }`}
            disabled={!changes}
          >
            Save Changes
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditProductCategory;
