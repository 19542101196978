import React, { useState } from 'react';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import NoPageFound from './pages/NoPageFound';
import AuthContext from './AuthContext';
import ProtectedWrapper from './ProtectedWrapper';
import Shops from './pages/Shops';
import Sales from './pages/Sales';
import PurchaseDetails from './pages/PurchaseDetails';
import Cookies from 'js-cookie';
import Quotations from './pages/Quotations';
import Order from './pages/Order';
import OrderUpdateForm from './components/UpdateOrder';
import EditShop from './components/EditShop';
import Products from './pages/Products';
import EditProduct from './components/EditProduct';
import ProductTypes from './pages/ProductTypes';
import ProductCategories from './pages/ProductCategories';
import EditProductCategory from './components/EditProductCategory';
import EditProductType from './components/EditProductType';
import ExcelEditor from './pages/BulkUpload';
import Coupons from './pages/Coupons';
import EditCoupon from './components/EditCoupon';
import Users from './pages/Users';
import UsersDetails from './components/UsersDetails';
import Queries from './pages/Queries';
import CustomServices from './pages/CustomServices';

const App = () => {
  const [user, setUser] = useState('');

  const signin = (newUser, callback) => {
    setUser(newUser);
    callback();
  };

  const signout = () => {
    setUser(null);
    Cookies.remove('ns_adm_auth_token');
  };

  const setGlobalUser = (globalUser) => {
    setUser(globalUser);
  };

  const value = { user, signin, signout, setGlobalUser };

  return (
    <AuthContext.Provider value={value}>
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route
            path='/'
            element={
              <ProtectedWrapper>
                <Layout />
              </ProtectedWrapper>
            }
          >
            <Route index element={<Dashboard />} />
            <Route path='/products' element={<Products />} />
            <Route path='/products/:productId' element={<EditProduct />} />
            <Route path='/purchase-details' element={<PurchaseDetails />} />
            <Route path='/sales' element={<Sales />} />
            <Route path='/manage-store' element={<Shops />} />
            <Route path='/manage-store/:shopId' element={<EditShop />} />
            <Route path='/quotations' element={<Quotations />} />
            <Route path='/orders' element={<Order />} />
            <Route path='/orders/:orderId' element={<OrderUpdateForm />} />
            <Route path='/product-types' element={<ProductTypes />} />
            <Route path='/product-types/:typeId' element={<EditProductType />} />
            <Route path='/product-categories' element={<ProductCategories />} />
            <Route path='/product-categories/:categoryId' element={<EditProductCategory />} />
            <Route path='/bulk-upload' element={<ExcelEditor />} />
            <Route path='/users' element={<Users />} />
            <Route path='/users/:userId' element={<UsersDetails />} />
            <Route path='/queries/' element={<Queries />} />
            <Route path='/coupons' element={<Coupons />} />
            <Route path='/coupons/:couponId' element={<EditCoupon />} />
            <Route path='/custom-service' element={<CustomServices />}/>
          </Route>
          <Route path='*' element={<NoPageFound />} />
        </Routes>
      </BrowserRouter>
    </AuthContext.Provider>
  );
};

export default App;
