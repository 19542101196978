const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;
const AUTH_API_URL = `${BASE_API_URL}/natsary-admin/auth`;

export const AuthService = {
  login: async (credentials) => {
    try {
      const response = await fetch(`${AUTH_API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });

      if (response.ok) {
        const { data } = await response.json();
        return data;
      } else {
        const { message } = await response.json();
        throw new Error(message);
      }
    } catch (error) {
      // Throw the error for the component to handle
      throw new Error(error.message);
    }
  },
};
