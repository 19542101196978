import Cookies from 'js-cookie';
const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;
const USER_API_URL = `${BASE_API_URL}/natsary-admin/user`;
export const UserService = {
  fetchUserInformation: async () => {
    try {
      const response = await fetch(`${USER_API_URL}/self`, {
        headers: {
          Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
        },
      });

      if (response.ok) {
        const { data } = await response.json();
        return data;
      } else {
        const { message } = await response.json(); // Assuming the error message is in JSON format
        throw new Error(message);
      }
    } catch (error) {
      // Throw the error for the component to handle
      throw new Error(error.message);
    }
  },

  fetchAllUsers: async (page) => {
    try {
      const response = await fetch(`${USER_API_URL}?page=${page}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
        },
      });

      if (response.ok) {
        const { data } = await response.json();
        return data;
      } else {
        const { message } = await response.json(); // Assuming the error message is in JSON format
        throw new Error(message);
      }
    } catch (error) {
      // Throw the error for the component to handle
      throw new Error(error.message);
    }
  },
};
