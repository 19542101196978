import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import AuthContext from '../AuthContext';
import {
  DocumentIcon,
  Squares2X2Icon,
  CubeIcon,
  CurrencyRupeeIcon,
  ShoppingCartIcon,
  BuildingStorefrontIcon,
  DocumentChartBarIcon,
  DocumentArrowUpIcon,
  UserGroupIcon,
  BanknotesIcon,
  QuestionMarkCircleIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';

function SideMenu() {
  const authContext = useContext(AuthContext);

  const menuItems = [
    { name: 'Dashboard', path: '/', icon: <Squares2X2Icon className='h-6 w-6' /> },
    { name: 'Products', path: '/products', icon: <CubeIcon className='h-6 w-6' /> },
    { name: 'Product Types', path: '/product-types', icon: <DocumentIcon className='h-6 w-6' /> },
    {
      name: 'Product Categories',
      path: '/product-categories',
      icon: <DocumentArrowUpIcon className='h-6 w-6' />,
    },
    // { name: "Purchase Details", path: "/purchase-details" , icon: <DocumentIcon className="h-6 w-6" />},
    {
      name: 'Manage Shops',
      path: '/manage-store',
      icon: <BuildingStorefrontIcon className='h-6 w-6' />,
    },
    { name: 'Orders', path: '/orders', icon: <ShoppingCartIcon className='h-6 w-6' /> },
    { name: 'Customized Orders', path: '/custom-service', icon: <PencilSquareIcon className='h-6 w-6' /> },
    { name: 'Sales', path: '/sales', icon: <CurrencyRupeeIcon className='h-6 w-6' /> },
    { name: 'Coupons', path: '/coupons', icon: <BanknotesIcon className='h-6 w-6' /> },
    { name: 'Queries', path: '/queries', icon: <QuestionMarkCircleIcon className='h-6 w-6' /> },
    { name: 'Users', path: '/users', icon: <UserGroupIcon className='h-6 w-6' /> },
    { name: 'Quotations', path: '/quotations', icon: <DocumentChartBarIcon className='h-6 w-6' /> },
  ];
  
  const user = authContext.user;

  if (user && user.role === 'super_admin') {
    menuItems.push({
      name: 'Manage Admins',
      path: '/manage-admins',
      icon: <UserGroupIcon className='h-6 w-6' />,
    });
  }
  return (
    <div className='w-64 bg-white h-screen'>
      <div className='px-4 py-6'>
        <nav aria-label='Main Nav' className='flex flex-col space-y-1'>
          {menuItems.map((item) => (
            <NavLink
              key={item.name}
              to={item.path}
              className={({ isActive }) =>
                `flex items-center gap-2 rounded-lg px-4 py-2 ${
                  isActive ? 'bg-green-300 text-gray-900' : 'text-gray-700 hover:bg-green-100'
                }`
              }
            >
              {item.icon}
              <span className='text-sm font-medium'> {item.name} </span>
            </NavLink>
          ))}
        </nav>
      </div>

      <div className='border-t border-gray-100 p-4'>
        <div className='flex items-center gap-2'>
          <div>
            <p className='text-xs'>
              <strong className='block font-medium'>{user.firstName}</strong>
              <span> {user.email} </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideMenu;
