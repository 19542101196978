import React, { useEffect, useState } from 'react';
import { QuotationService } from '../services/quotation';
import { NSTable } from '@natsary/ns-fusion';

const ViewQuotations = () => {
  const [deliveredQuotations, setDeliveredQuotations] = useState([]);
  const formatData = (data) => {
    return data.map((entry) => {
      const formattedEntry = {};
      for (const [key, value] of Object.entries(entry)) {
        if (key === '_id' || key === '__v' || key === 'updatedAt') {
          continue;
        }
        if (key === 'createdAt') {
          formattedEntry[key] = new Date(value).toLocaleString();
        } else if (Array.isArray(value)) {
          const allProducts = value.map((item, index) => (
            <div key={index} className='border-2 p-1 rounded-md'>
              <div>Product Name: {item.name}</div>
              <div>Rate: {item.rate || ''}</div>
              <div>Quantity: {item.quantity}</div>
              <div>Price: {item.price}</div>
            </div>
          ));
          formattedEntry[key] = <div className='h-20 overflow-y-scroll'>{allProducts}</div>;
        } else if (typeof value === 'object' && value !== null) {
          formattedEntry[key] = <div>{Object.values(value).join(', ')}</div>;
        } else {
          formattedEntry[key] = value;
        }
      }
      return formattedEntry;
    });
  };
  const fetchAllQuotations = async () => {
    const quotations = await QuotationService.fetchAllQuotations();
    setDeliveredQuotations(formatData(quotations));
  };
  useEffect(() => {
    fetchAllQuotations();
  }, []);
  return (
    <div className='overflow-x-scroll'>
      <NSTable data={deliveredQuotations} />
    </div>
  );
};

export default ViewQuotations;
