import { Fragment, useContext } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  BellIcon,
  XMarkIcon,
  UserIcon,
  ArrowLeftOnRectangleIcon,
} from '@heroicons/react/24/outline';
import AuthContext from '../AuthContext';
import { Link, useNavigate } from 'react-router-dom';

const navigation = [
  { name: 'Dashboard', href: '/', current: true },
  { name: 'Products', href: '/products', current: false },
  { name: 'Purchase Details', href: '/purchase-details', current: false },
  { name: 'Sales', href: '/sales', current: false },
  { name: 'Manage Shops', href: '/manage-store', current: false },
  { name: 'Orders', href: '/orders', current: false },
  { name: 'Quotations', href: '/quotations', current: false },
];

const userNavigation = [{ name: 'Sign out', href: '/login' }];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Header() {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const user = authContext.user;
  return (
    <>
      <div className='min-h-full bg-white shadow'>
        <Disclosure as='nav' className='bg-white'>
          {({ open }) => (
            <>
              <div className='mx-auto px-4 sm:px-6 lg:px-8'>
                <div className='flex h-16 items-center justify-between'>
                  <div className='flex items-center'>
                    <div className='flex-shrink-0'>
                      <div className='flex justify-center items-center gap-2'>
                        <img
                          className='h-8'
                          src={require('../assets/natsary.webp')}
                          alt='Products Management System'
                        />
                        <span className='font-bold text-dark'>| {user.role.toUpperCase()}</span>
                      </div>
                    </div>
                  </div>
                  <div className='hidden md:block'>
                    <div className='ml-4 flex items-center md:ml-6'>
                      <button
                        type='button'
                        className='rounded-full p-1 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'
                      >
                        <span className='sr-only'>View notifications</span>
                        <BellIcon className='h-6 w-6' aria-hidden='true' />
                      </button>
                      <button
                        onClick={() => {
                          // eslint-disable-next-line no-restricted-globals
                          if (confirm('Are you sure?')) {
                            authContext.signout();
                            navigate('/login');
                          }
                        }}
                        type='button'
                        className='rounded-full p-1 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'
                      >
                        <span className='sr-only'>Sign out</span>
                        <ArrowLeftOnRectangleIcon className='h-6 w-6' aria-hidden='true' />
                      </button>
                    </div>
                  </div>
                  <div className='-mr-2 flex md:hidden'>
                    {/* Mobile menu button */}
                    <Disclosure.Button className='inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'>
                      <span className='sr-only'>Open main menu</span>
                      {open ? (
                        <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                      ) : (
                        <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className='md:hidden'>
                <div className='space-y-1 px-2 pb-3 pt-2 sm:px-3'>
                  {navigation.map((item) => (
                    <Link to={item.href} key={item.name}>
                      <Disclosure.Button
                        key={item.name}
                        as='a'
                        className={classNames(
                          item.current
                            ? 'bg-green-700 text-white'
                            : 'text-green-700 hover:bg-green-100 hover:text-white',
                          'block rounded-md px-3 py-2 text-base font-medium',
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    </Link>
                  ))}
                </div>
                <div className='border-t border-gray-700 pt-4 pb-3'>
                  <div className='flex items-center px-5'>
                    <div className='flex-shrink-0'>
                      <UserIcon className='h-6 w-6' aria-hidden='true' />
                    </div>
                    <div className='ml-3'>
                      <div className='text-base font-medium leading-none text-dark-1000'>
                        {authContext.user.firstName + ' ' + authContext.user.lastName}
                      </div>
                      <div className='text-sm font-medium leading-none text-gray-400'>
                        {authContext.user.email}
                      </div>
                    </div>
                    <button
                      type='button'
                      className='ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'
                    >
                      <span className='sr-only'>View notifications</span>
                      <BellIcon className='h-6 w-6' aria-hidden='true' />
                    </button>
                  </div>
                  <div className='mt-3 space-y-1 px-2'>
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as='a'
                        href={item.href}
                        onClick={() => {
                          // eslint-disable-next-line no-restricted-globals
                          if (confirm('Are you sure?')) {
                            authContext.signout();
                            navigate('/login');
                          }
                        }}
                        className='block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white'
                      >
                        <span>{item.name} </span>
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
}
