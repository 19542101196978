import React, { useState, useEffect, useContext } from 'react';
import AddProduct from '../components/AddProduct';
import AuthContext from '../AuthContext';
import Cookies from 'js-cookie';
import { NSInput, NSLoader, NSPagination, NSSearchBox, NSTypography } from '@natsary/ns-fusion';
import { Link } from 'react-router-dom';
import AddService from '../components/AddService';
import Spinner from '../components/Spinner';
import { ProductService } from '../services/product';

function Products() {
  const [showProductModal, setShowProductModal] = useState(false);
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [products, setAllProducts] = useState([]);
  const [updatePage, setUpdatePage] = useState(true);
  const [stores, setAllStores] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchProductsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // Fetching Data of All Products
  const fetchProductsData = async () => {
    try {
      const { products, total} = await ProductService.fetchProducts(page);
      setTotalItems(total);
      setAllProducts(products);
    } catch {

    } finally {
      setLoading(false);
    }
  };

  const searchProducts = async (searchValue) => {
    setLoading(true);
    try {
      const searchProductsData = await ProductService.searchProducts(searchValue, page);
      setAllProducts(searchProductsData);
    } catch {

    } finally {
      setLoading(false);
    }
  };

  // Modal for Product ADD
  const addProductModalSetting = () => {
    setShowProductModal(!showProductModal);
  };

  const addServiceModalSetting = () => {
    setShowServiceModal(!showServiceModal);
  };

  // Handle Page Update
  const handlePageUpdate = () => {
    setUpdatePage(!updatePage);
  };

  const refreshData = () => {
    fetchProductsData();
  };

  const debounce = (func, delay) => {
    let timeoutId;

    return function (...args) {
      const context = this;

      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  };

  const handleSearch = debounce((value) => {
    if(value.length > 3)
    searchProducts(value);
    // onSearch(value);
  }, 300); // Adjust the delay as needed

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    handleSearch(event.target.value);
  };


  return (
    <div className='w-full flex justify-center h-screen'>
      <div className=' flex flex-col gap-5 w-11/12'>
        <div className='bg-white rounded p-3'>
          <span className='font-semibold px-4'>Overall products</span>
          <div className=' flex flex-col md:flex-row justify-center items-center  '>
            <div className='flex flex-col p-10  w-full  md:w-3/12  '>
              <span className='font-semibold text-blue-600 text-base'>Total Products</span>
              <span className='font-semibold text-gray-600 text-base'>{totalItems}</span>
              <span className='font-thin text-gray-400 text-xs'>Last 7 days</span>
            </div>
            <div className='flex flex-col gap-3 p-10   w-full  md:w-3/12 sm:border-y-2  md:border-x-2 md:border-y-0'>
              <span className='font-semibold text-yellow-600 text-base'>Stores</span>
              <div className='flex gap-8'>
                <div className='flex flex-col'>
                  <span className='font-semibold text-gray-600 text-base'>{stores.length}</span>
                  <span className='font-thin text-gray-400 text-xs'>Last 7 days</span>
                </div>
                <div className='flex flex-col'>
                  <span className='font-semibold text-gray-600 text-base'>$2000</span>
                  <span className='font-thin text-gray-400 text-xs'>Revenue</span>
                </div>
              </div>
            </div>
            <div className='flex flex-col gap-3 p-10  w-full  md:w-3/12  sm:border-y-2 md:border-x-2 md:border-y-0'>
              <span className='font-semibold text-purple-600 text-base'>Top Selling</span>
              <div className='flex gap-8'>
                <div className='flex flex-col'>
                  <span className='font-semibold text-gray-600 text-base'>5</span>
                  <span className='font-thin text-gray-400 text-xs'>Last 7 days</span>
                </div>
                <div className='flex flex-col'>
                  <span className='font-semibold text-gray-600 text-base'>$1500</span>
                  <span className='font-thin text-gray-400 text-xs'>Cost</span>
                </div>
              </div>
            </div>
            <div className='flex flex-col gap-3 p-10  w-full  md:w-3/12  border-y-2  md:border-x-2 md:border-y-0'>
              <span className='font-semibold text-red-600 text-base'>Low Stocks</span>
              <div className='flex gap-8'>
                <div className='flex flex-col'>
                  <span className='font-semibold text-gray-600 text-base'>12</span>
                  <span className='font-thin text-gray-400 text-xs'>Ordered</span>
                </div>
                <div className='flex flex-col'>
                  <span className='font-semibold text-gray-600 text-base'>2</span>
                  <span className='font-thin text-gray-400 text-xs'>Not in Stock</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showServiceModal && (
          <AddProduct
            addServiceModalSetting={addServiceModalSetting}
            handlePageUpdate={handlePageUpdate}
            type='service'
          />
        )}

        {showProductModal && (
          <AddProduct
            addProductModalSetting={addProductModalSetting}
            handlePageUpdate={handlePageUpdate}
            refreshData={refreshData}
            type='product'
          />
        )}

        {/* Table  */}
        <div className='overflow-x-auto border p-6 bg-white rounded-lg shadow-lg'>
          <div className='flex justify-between pt-5 pb-3 px-3'>
            <div className='flex gap-4 justify-center items-center '>
              <span className='font-bold'>Products</span>
            </div>

            <div className='flex gap-4'>
              <button
                className='bg-blue-400 hover:bg-blue-700 text-white font-bold p-2 text-xs  rounded'
                onClick={addServiceModalSetting}
              >
                {/* <Link to="/products/add-product">Add Product</Link> */}
                Add Service
              </button>
              <button
                className='bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 text-xs  rounded'
                onClick={addProductModalSetting}
              >
                {/* <Link to="/products/add-product">Add Product</Link> */}
                Add Product
              </button>
            </div>
          </div>
          <NSInput
            placeholder={'Search products here...'}
            value={searchTerm}
            onChange={handleChange}
          />

          <table className='min-w-full divide-y-2 divide-gray-200 text-sm'>
            <thead>
              <tr>
                <th className='whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900'>
                  Product Name
                </th>
                <th className='whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900'>
                  Stock
                </th>
                <th className='whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900'>
                  Price
                </th>
                <th className='whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900'>
                  Availibility
                </th>
                <th className='whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900'>
                  Active
                </th>
              </tr>
            </thead>

            <tbody className='divide-y divide-gray-200'>
              {loading && <NSLoader isVisible/>}
            

              {products.map((element) => {
                return (
                  <tr key={element._id}>
                    <td className='whitespace-nowrap px-4 py-2  text-gray-900'>
                      <Link className='text-blue-600 underline' to={`/products/${element._id}`}>
                        {element.name}
                      </Link>
                    </td>
                    <td className='whitespace-nowrap px-4 py-2 text-gray-700'>
                      {element.quantity}
                    </td>
                    <td className='whitespace-nowrap px-4 py-2 text-gray-700'>
                      <span className='line-through mr-1'>₹{element.mrp}</span>₹{element.price}
                    </td>
                    <td className='whitespace-nowrap px-4 py-2 text-gray-700'>
                      {element.inStock ? 'In Stock' : 'Not in Stock'}
                    </td>
                    <td className='whitespace-nowrap px-4 py-2 text-gray-700'>
                      {element.active ? 'Yes' : 'No'}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <NSPagination
          totalItems={totalItems}
          itemsPerPage={10}
          onPageChange={(activePage) => {
            setPage(activePage - 1);
          }}
          activePage={page + 1}
        />
      </div>
    </div>
  );
}

export default Products;
