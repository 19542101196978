import { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { UserService } from './services/user';
import { NSLoader } from '@natsary/ns-fusion';
import AuthContext from './AuthContext';

function ProtectedWrapper(props) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const data = await UserService.fetchUserInformation();
        if (data) {
          setLoading(false);
          setUser(data);
          authContext.setGlobalUser(data);
        }
      } catch {
        Cookies.remove('ns_adm_auth_token');
      }

    };
    if (Cookies.get('ns_adm_auth_token')) {
      fetchUserInfo();
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <NSLoader isVisible />;
  }

  if (user === null) {
    return <Navigate to='/login' replace />;
  }

  return props.children;
}
export default ProtectedWrapper;
