// src/components/ExcelEditor.js
import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { useTable } from 'react-table';

const ExcelEditor = () => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      console.log('Parsed JSON Data:', jsonData); // Debugging output

      if (jsonData.length > 0) {
        const headerRow = jsonData[0];
        const cols = headerRow.map((col, index) => ({
          Header: col || `Column ${index + 1}`,
          accessor: `col${index}`,
        }));
        setColumns(cols);

        const rows = jsonData.slice(1).map((row) => {
          const rowObj = {};
          row.forEach((cell, i) => {
            rowObj[`col${i}`] = cell;
          });
          return rowObj;
        });

        console.log('Parsed Rows:', rows); // Debugging output

        setData(rows);
      }
    };

    reader.readAsBinaryString(file);
  };

  const handleInputChange = (e, rowIndex, columnId) => {
    const newData = [...data];
    newData[rowIndex][columnId] = e.target.value;
    setData(newData);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const handleSubmit = () => {
    console.log('Submitted Data:', data);
    // Add your submission logic here
  };

  return (
    <div className='p-4'>
      <input type='file' accept='.xlsx, .xls' onChange={handleFileUpload} className='mb-4' />
      {data.length > 0 && (
        <div>
          <table
            {...getTableProps()}
            className='min-w-full bg-white border-collapse border border-gray-200'
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className='w-full bg-gray-200'>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} className='px-4 py-2 border border-gray-300'>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className='border-t'>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()} className='px-4 py-2 border border-gray-300'>
                        <input
                          type='text'
                          value={cell.value || ''}
                          onChange={(e) => handleInputChange(e, row.index, cell.column.id)}
                          className='w-full border rounded px-2'
                        />
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <button onClick={handleSubmit} className='mt-4 bg-blue-500 text-white px-4 py-2 rounded'>
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

export default ExcelEditor;
