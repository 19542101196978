import { NSLoader } from '@natsary/ns-fusion';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

const EditProductType = () => {
  const { typeId } = useParams();
  const [productType, setProductType] = useState(null);
  const [loading, setLoading] = useState(true);
  const [changes, setChanges] = useState(false);

  useEffect(() => {
    fetchProductTypeDetails();
  }, []);

  const fetchProductTypeDetails = async () => {
    try {
      const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;

      const response = await fetch(`${BASE_API_URL}/natsary-admin/productType/${typeId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
        },
      });
      const { data } = await response.json();
      setProductType(data.productType);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching product type details:', error);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;

    try {
      const response = await fetch(
        `${BASE_API_URL}/natsary-admin/productType/update/${typeId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
          },
          body: JSON.stringify({
            name: productType.name,
            code: productType.code,
            description: productType.description,
            active: productType.active,
          }),
        },
      );
      if (response.ok) {
        alert('Product type details updated successfully!');
        setChanges(false);
      } else {
        alert('Failed to update product type details.');
      }
    } catch (error) {
      console.error('Error saving product type details:', error);
    }
  };

  if (loading) {
    return <NSLoader isVisible />;
  }

  return (
    <div className='w-full p-4 bg-gray-100 min-h-screen flex flex-col items-center'>
      <div className='w-full overflow-x-auto p-6 bg-white rounded-lg shadow-lg'>
        <h2 className='text-3xl font-semibold mb-8 text-gray-800'>Edit Product Type</h2>
        <form onSubmit={handleSave}>
          <div className='mb-4'>
            <label htmlFor='name' className='block text-gray-700 text-sm font-bold mb-2'>
              Product Type Name
            </label>
            <input
              type='text'
              id='name'
              name='name'
              value={productType.name}
              onChange={(e) => {
                setProductType({ ...productType, name: e.target.value });
                setChanges(true);
              }}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            />
          </div>

          <div className='mb-4'>
            <label htmlFor='code' className='block text-gray-700 text-sm font-bold mb-2'>
              Product Type Code
            </label>
            <input
              type='text'
              id='code'
              name='code'
              value={productType.code}
              onChange={(e) => {
                setProductType({ ...productType, code: e.target.value });
                setChanges(true);
              }}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            />
          </div>

          <div className='mb-4'>
            <label htmlFor='description' className='block text-gray-700 text-sm font-bold mb-2'>
              Description
            </label>
            <textarea
              id='description'
              name='description'
              value={productType.description}
              onChange={(e) => {
                setProductType({ ...productType, description: e.target.value });
                setChanges(true);
              }}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            />
          </div>

          <div className='mb-4'>
            <label htmlFor='active' className='block text-gray-700 text-sm font-bold mb-2'>
              Active
            </label>
            <input
              type='checkbox'
              id='active'
              name='active'
              checked={productType.active}
              onChange={(e) => {
                setProductType({ ...productType, active: e.target.checked });
                setChanges(true);
              }}
              className='h-6 w-6 text-blue-600'
            />
          </div>

          <button
            type='submit'
            className={`mt-8 w-full py-3 rounded-md text-white ${
              changes ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-300 cursor-not-allowed'
            }`}
            disabled={!changes}
          >
            Save Changes
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditProductType;
