import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { NSBadge, NSButton, NSConfirm, NSLoader } from '@natsary/ns-fusion';
import { getBGColorFromStatus } from '../utils/util';

const OrderUpdateForm = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    orderStatus: '',
    paymentStatus: '',
    refundStatus: '',
    refundMessage: '',
  });

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;

        const response = await fetch(`${BASE_API_URL}/natsary-admin/orders/${orderId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
          },
        });
        const { data } = await response.json();
        setOrder(data);
        setLoading(false);
        setFormData({
          orderStatus: data.orderStatus,
          paymentStatus: data.paymentStatus,
          refundStatus: data.refundStatus.status,
          refundMessage: data.refundStatus.message || '',
        });
      } catch (error) {
        console.error('Error fetching order:', error);
      }
    };
    setLoading(true);
    fetchOrder();
  }, [orderId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;
      
      const response = await fetch(`${BASE_API_URL}/natsary-admin/orders/${orderId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
        },
        body: JSON.stringify({
          orderStatus: formData.orderStatus,
          paymentStatus: formData.paymentStatus,
          refundStatus: {
            status: formData.refundStatus,
            message: formData.refundMessage,
          },
        }),
      });
      const { data } = await response.json();
      setIsConfirmOpen(false);
      setLoading(false);
    } catch (error) {
      console.error('Error updating order:', error);
    }
  };

  if (!order) {
    return <NSLoader isVisible />;
  }
  const isDisabled = () => {
    return (
      formData.orderStatus === order.orderStatus &&
      formData.paymentStatus === order.paymentStatus &&
      formData.refundStatus === order.refundStatus.status &&
      formData.refundMessage === (order.refundStatus.message || '')
    );
  };
  return (
    <div className='w-full p-4 bg-gray-100 min-h-screen flex flex-col items-center'>
      {isConfirmOpen && (
        <NSConfirm
          message='Are you sure you want to update the order?'
          onConfirm={handleSubmit}
          onCancel={() => setIsConfirmOpen(false)}
          title='Confirm Update'
          titleIconName='check-circle'
        />
      )}
      <NSLoader isVisible={loading} />

      <div className='w-full p-4 bg-white shadow-md rounded-lg mb-6'>
        <h2 className='text-xl font-bold mb-4'>Order Number: {order.publicOrderId}</h2>
        <div>
          <span className='font-semibold'>Order UUID:</span> {order._id}
        </div>
        <div>
          <span className='font-semibold'>User ID:</span> {order.userId.firstName}{' '}
          {order.userId.lastName} - {order.userId.phone}
        </div>
        <div>
          <span className='font-semibold'>Merchant Transaction ID:</span>{' '}
          {order.merchantTransactionId}
        </div>
        <div>
          <span className='font-semibold'>Total Amount:</span> ${order.totalAmount}
        </div>
        <div>
          <span className='font-semibold'>Public Order ID:</span> {order.publicOrderId}
        </div>
        <div>
          <span className='font-semibold'>Delivery Address:</span>{' '}
          {JSON.stringify(order.deliveryAddress, null, 2)}
        </div>
        <div>
          <span className='font-semibold'>Order Status:</span>{' '}
          <NSBadge
            text={order.orderStatus}
            backgroundColor={getBGColorFromStatus(order.orderStatus)}
          />
        </div>
        <div>
          <span className='font-semibold'>Payment Status:</span> {order.paymentStatus}
        </div>
        <div>
          <span className='font-semibold'>Refund Status:</span> {order.refundStatus.status}
        </div>
        <div>
          <span className='font-semibold'>Refund Message:</span> {order.refundStatus.message}
        </div>
      </div>

      <div className='w-full'>
        <h2 className='text-xl font-bold mb-4'>Product List</h2>
        <table className='w-full border-collapse border border-gray-400'>
          <thead>
            <tr>
              <th className='border border-gray-400 px-4 py-2 text-left'>Product Name</th>
              <th className='border border-gray-400 px-4 py-2'>Quantity</th>
              <th className='border border-gray-400 px-4 py-2'>Price</th>
            </tr>
          </thead>
          <tbody>
            {order.products.map((product, index) => (
              <tr key={index}>
                <td className='border border-gray-400 px-4 py-2'>{product.product.name}</td>
                <td className='border border-gray-400 px-4 py-2 text-center'>{product.quantity}</td>
                <td className='border border-gray-400 px-4 py-2 text-center'>
                  INR {product.product.price}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className='flex flex-col items-end'>
          {order.priceBreakUp && order.priceBreakUp.couponDetails && (
            <div className='flex gap-2'>
              <p>Coupon applied</p>
              <Link
                className='text-blue-600 underline'
                to={`/coupons/${order.priceBreakUp.couponDetails.id}`}
              >
                {order.priceBreakUp.couponDetails.code}
              </Link>
            </div>
          )}
          <div className='flex gap-2'>
            <p>Initial Price</p>
            <p>₹{order.priceBreakUp.totalAmount}</p>
          </div>
          <div className='flex gap-2'>
            <p>Discount</p>
            <p>₹{order.priceBreakUp.discount}</p>
          </div>
          <div className='flex gap-2'>
            <p>Final Price</p>
            <p>₹{order.priceBreakUp.finalPrice}</p>
          </div>
        </div>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          setIsConfirmOpen(true);
        }}
        className='w-full p-4 bg-white shadow-md rounded-lg mt-6'
      >
        <h2 className='text-xl font-bold mb-4'>Update Order</h2>
        <div>
          <label className='block text-gray-700'>Order Status</label>
          <select
            name='orderStatus'
            value={formData.orderStatus}
            onChange={handleChange}
            className='mt-1 block w-full p-2 border border-gray-300 rounded-md'
          >
            <option value='initiated'>Initiated</option>
            <option value='placed'>Placed</option>
            <option value='accepted'>Accepted</option>
            <option value='dispatched'>Dispatched</option>
            <option value='delivered'>Delivered</option>
            <option value='returned'>Returned</option>cancelled
            <option value='cancelled'>Cancelled</option>
          </select>
        </div>

        <div>
          <label className='block text-gray-700'>Payment Status</label>
          <select
            name='paymentStatus'
            value={formData.paymentStatus}
            onChange={handleChange}
            className='mt-1 block w-full p-2 border border-gray-300 rounded-md'
          >
            <option value='PAYMENT_INITIATED'>Payment Initiated</option>
            <option value='PAYMENT_SUCCESS'>Success</option>
            <option value='PAYMENT_PENDING'>Pending</option>
            <option value='PAYMENT_DECLINED'>Declined</option>
          </select>
        </div>

        <div>
          <label className='block text-gray-700'>Refund Status</label>
          <select
            name='refundStatus'
            value={formData.refundStatus}
            onChange={handleChange}
            className='mt-1 block w-full p-2 border border-gray-300 rounded-md'
          >
            <option value='not_initiated'>Not Initiated</option>
            <option value='initiated'>Initiated</option>
            <option value='progress'>progress</option>
            <option value='completed'>Completed</option>
          </select>
        </div>

        <div>
          <label className='block text-gray-700'>Refund Message</label>
          <input
            type='text'
            name='refundMessage'
            value={formData.refundMessage}
            onChange={handleChange}
            className='mt-1 block w-full p-2 border border-gray-300 rounded-md'
          />
        </div>
        <NSButton
          type='submit'
          className='w-full bg-blue-500 text-white p-2 rounded-md  mt-4'
          disabled={isDisabled()}
        >
          Update Order
        </NSButton>
      </form>
    </div>
  );
};

export default OrderUpdateForm;
