import React, { useEffect, useState } from 'react';
import {
  Document,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
  Image,
  usePDF,
  Link,
} from '@react-pdf/renderer';
import { QuotationService } from '../services/quotation';
import { NSAlert, NSButton } from '@natsary/ns-fusion';
import { XMarkIcon } from '@heroicons/react/24/outline';

const styles = StyleSheet.create({
  formContainer: {
    width: '100%',
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 5,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },
  input: {
    borderWidth: 1,
    borderColor: '#ccc',
    padding: 12,
    width: '100%',
    fontSize: 16,
    boxSizing: 'border-box',
  },
  addButton: {
    padding: 12,
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: 5,
    cursor: 'pointer',
    fontSize: 16,
  },
  pdfViewerContainer: {
    width: '100%',
  },
});

const AddQuotation = ({ addedQuotation }) => {
  const [quotationNumber, setQuotationNumber] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [products, setProducts] = useState([{ name: '', price: '', quantity: '', rate: '' }]);
  const [alert, setAlert] = useState(null);

  const handleProductInputChange = (index, name, value) => {
    const updatedProducts = [...products];
    updatedProducts[index][name] = value;
    setProducts(updatedProducts);
  };

  const addProductRow = () => {
    setProducts([...products, { name: '', price: '', quantity: '', rate: '' }]);
  };

  const getTotalPrice = () => {
    let totalPrice = 0;
    products.forEach((item) => {
      totalPrice += parseInt(item.price === '' ? 0 : item.price);
    });
    return totalPrice;
  };

  const generatePDF = (name, email, quotationProducts = [], quotation, phone) => {
    const MyDocument = (
      <Document>
        <Page size='A4'>
          <View style={{ padding: 20 }}>
            <View
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image src={require('../assets/natsary.webp')} style={{ width: 100, height: 40 }} />
              <Text style={{ fontSize: 12, marginTop: '20px' }}>Quotation {quotation}</Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <View style={{ marginBottom: 20, marginTop: 20, fontSize: 12 }}>
                <Text style={{ marginBottom: 5 }}>To</Text>
                <Text style={{ marginBottom: 5 }}>{name}</Text>
                <Text style={{ marginBottom: 5 }}>{email}</Text>
                <Text style={{ marginBottom: 5 }}>{phone}</Text>
              </View>

              <View
                style={{
                  marginBottom: 20,
                  marginTop: 20,
                  fontSize: 12,
                  alignItems: 'flex-end',
                }}
              >
                <Text style={{ marginBottom: 5 }}>From</Text>
                <Text style={{ marginBottom: 5 }}>Natsary Services Private Limited</Text>
                <Link style={{ marginBottom: 5 }}>help@natsary.com</Link>
                <Link href='https://www.natsary.com' style={{ marginBottom: 5 }}>
                  https://www.natsary.com
                </Link>
                <Text style={{ marginBottom: 5 }}>
                  Lalkuthi Purbayan, Rajarhat Gopalpur, Kolkata 700136
                </Text>
              </View>
            </View>

            <View
              style={{
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: '#000',
                marginBottom: 20,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  borderBottomWidth: 1,
                  borderBottomColor: '#000',
                }}
              >
                <Text
                  style={{
                    ...styles.input,
                    flex: 2,
                    textAlign: 'center',
                    backgroundColor: '#52D65C',
                  }}
                >
                  Product Name
                </Text>
                <Text
                  style={{
                    ...styles.input,
                    flex: 1,
                    textAlign: 'center',
                    backgroundColor: '#52D65C',
                  }}
                >
                  Rate
                </Text>
                <Text
                  style={{
                    ...styles.input,
                    flex: 1,
                    textAlign: 'center',
                    backgroundColor: '#52D65C',
                  }}
                >
                  Quantity
                </Text>
                <Text
                  style={{
                    ...styles.input,
                    flex: 1,
                    textAlign: 'center',
                    backgroundColor: '#52D65C',
                  }}
                >
                  Price (INR)
                </Text>
              </View>
              {quotationProducts.map((product, index) => (
                <View
                  key={index}
                  style={{
                    flexDirection: 'row',
                    borderBottomWidth: 1,
                    borderBottomColor: '#000',
                  }}
                >
                  <Text style={{ ...styles.input, flex: 2 }}>{product.name}</Text>
                  <Text style={{ ...styles.input, flex: 1, textAlign: 'center' }}>
                    {product.rate}
                  </Text>
                  <Text style={{ ...styles.input, flex: 1, textAlign: 'center' }}>
                    {product.quantity}
                  </Text>
                  <Text style={{ ...styles.input, flex: 1, textAlign: 'center' }}>
                    {product.price}
                  </Text>
                </View>
              ))}
            </View>
            <View style={{ marginBottom: 20 }}>
              <Text style={{ marginBottom: 5 }}>Total Price: {getTotalPrice()}</Text>
            </View>
          </View>
        </Page>
      </Document>
    );

    return MyDocument;
  };
  const [instance, updateInstance] = usePDF({ document: generatePDF() });

  const handleSendEmail = async (e) => {
    e.preventDefault();
    // Create PDF blob
    const pdfBlob = instance.blob;

    // Send PDF blob to backend
    const formData = new FormData();
    formData.append('pdf', pdfBlob, 'quotation.pdf');
    formData.append('customerEmail', customerEmail);
    formData.append('customerName', customerName);
    formData.append('products', JSON.stringify(products));

    try {
      const quotation = await QuotationService.sendQuotation(formData);
      setQuotationNumber(`#${quotation.quotation}`);
      setAlert({
        type: 'success',
        message: 'Successfully sent the quotation!',
      });
      setQuotationNumber('');
      setCustomerName('');
      setCustomerEmail('');
      setCustomerPhone('');
      setProducts([{ name: '', price: '', quantity: '', rate: '' }]);
    } catch {
      setAlert({
        type: 'error',
        message: 'Something went wrong!',
      });
    }
  };

  const removeProductRow = (indexToRemove) => {
    setProducts(products.filter((_, index) => index !== indexToRemove));
  };

  useEffect(() => {
    updateInstance(
      generatePDF(customerName, customerEmail, products, quotationNumber, customerPhone),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerName, customerEmail, products, quotationNumber, customerPhone]);
  return (
    <div>
      {alert && <NSAlert type={alert.type} message={alert.message} />}
      <div className='flex md:flex-row flex-col gap-2 w-full'>
        <div id='form-container' className='p-4'>
          <div className='mb-4'>
            <label className='block mb-2'>Customer Name:</label>
            <input
              type='text'
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              className='w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500'
            />
          </div>
          <div className='mb-4'>
            <label className='block mb-2'>Customer Phone:</label>
            <input
              type='text'
              value={customerPhone}
              onChange={(e) => setCustomerPhone(e.target.value)}
              className='w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500'
            />
          </div>
          <div className='mb-4'>
            <label className='block mb-2'>Customer Email:</label>
            <input
              type='email'
              value={customerEmail}
              onChange={(e) => setCustomerEmail(e.target.value)}
              className='w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500'
            />
          </div>

          {/* Products Section */}
          <div className='border rounded p-4 mb-4'>
            <label className='block mb-2'>Products:</label>
            {products.map((product, index) => (
              <div key={index} className='mb-4'>
                <input
                  type='text'
                  placeholder='Product Name'
                  value={product.name}
                  onChange={(e) => handleProductInputChange(index, 'name', e.target.value)}
                  className='w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500'
                />
                <input
                  type='text'
                  placeholder='Rate'
                  value={product.rate}
                  onChange={(e) => handleProductInputChange(index, 'rate', e.target.value)}
                  className='w-full mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500'
                />
                <input
                  type='text'
                  placeholder='Quantity'
                  value={product.quantity}
                  onChange={(e) => handleProductInputChange(index, 'quantity', e.target.value)}
                  className='w-full mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500'
                />
                <input
                  type='text'
                  placeholder='Price'
                  value={product.price}
                  onChange={(e) => handleProductInputChange(index, 'price', e.target.value)}
                  className='w-full mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500'
                />
                <NSButton className='bg-red-500 text-white' onClick={() => removeProductRow(index)}>
                  <XMarkIcon className='h-6 w-6' />
                </NSButton>
              </div>
            ))}
            <NSButton
              onClick={addProductRow}
              className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-2'
            >
              Add Product
            </NSButton>
          </div>
          {/* End of Products Section */}

          <NSButton
            onClick={handleSendEmail}
            className='bg-green-700 hover:bg-green-600 hover:cursor-pointer text-white font-bold py-2 px-4 rounded'
          >
            Send Quotation
          </NSButton>
        </div>

        <div id='pdf-viewer' style={styles.pdfViewerContainer}>
          <PDFViewer width='100%' height='800' showToolbar={false}>
            {generatePDF(customerName, customerEmail, products)}
          </PDFViewer>
        </div>
      </div>
    </div>
  );
};

export default AddQuotation;
