import { NSLoader } from '@natsary/ns-fusion';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

const EditShop = ({ userId }) => {
  const { shopId } = useParams();
  const [user, setUser] = useState(null);
  const [isUserRegistered, setIsUserRegistered] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [changes, setChanges] = useState(false);

  useEffect(() => {
    fetchShopDetails();
  }, [userId]);

  const fetchShopDetails = async () => {
    try {
      const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;
      
      const response = await fetch(`${BASE_API_URL}/natsary-admin/user/${shopId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
        },
      });
      const { data } = await response.json();
      setUser(data.user);
      setIsUserRegistered(data.user.isUserRegistered);
      setIsActive(data.user.active);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const handleSave = async () => {
    try {
      const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;
      const response = await fetch(`${BASE_API_URL}/natsary-admin/user/udpate/${shopId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
        },
        body: JSON.stringify({
          isUserRegistered,
          active: isActive,
        }),
      });
      if (response.ok) {
        alert('User details updated successfully!');
        setChanges(false);
      } else {
        alert('Failed to update user details.');
      }
    } catch (error) {
      console.error('Error saving user details:', error);
    }
  };

  if (loading) {
    return <NSLoader isVisible />;
  }

  return (
    <div className='w-full p-4 bg-gray-100 min-h-screen flex flex-col items-center'>
      <div className='w-full overflow-x-auto p-6 bg-white rounded-lg shadow-lg'>
        <h2 className='text-3xl font-semibold mb-8 text-gray-800'>User Details</h2>
        <table className='min-w-full divide-y-2 divide-gray-200 text-sm'>
          <tbody className='divide-y divide-gray-200'>
            {[
              ['Region', user.region],
              ['First Name', user.firstName],
              ['Last Name', user.lastName],
              ['Role', user.role],
              ['Email', user.email],
              ['Phone', user.phone],
              ['DOB', user.dob],
              ['Gender', user.gender],
              ['Product Interest', user.productInterest.join(', ')],
              ['Referral Code', user.referalCode],
              ['Wallet Amount', user.walletAmount],
              ['Extra Data', JSON.stringify(user.extradata)],
              ['PAN', user.pan],
            ].map(([label, value]) => (
              <tr key={label}>
                <td className='whitespace-nowrap px-4 py-2 font-medium text-gray-900'>{label}</td>
                <td className='whitespace-nowrap px-4 py-2 text-gray-700'>{value}</td>
              </tr>
            ))}

            <tr className='border-t border-gray-200'>
              <td className='whitespace-nowrap px-4 py-2 font-medium text-gray-900'>
                Is User Registered
              </td>
              <td className='whitespace-nowrap px-4 py-2 text-gray-700'>
                <input
                  type='checkbox'
                  className='h-6 w-6 text-blue-600'
                  checked={isUserRegistered}
                  onChange={(e) => {
                    setIsUserRegistered(e.target.checked);
                    setChanges(true);
                  }}
                />
              </td>
            </tr>

            <tr className='border-t border-gray-200'>
              <td className='whitespace-nowrap px-4 py-2 font-medium text-gray-900'>Active</td>
              <td className='whitespace-nowrap px-4 py-2 text-gray-700'>
                <input
                  type='checkbox'
                  className='h-6 w-6 text-blue-600'
                  checked={isActive}
                  onChange={(e) => {
                    setIsActive(e.target.checked);
                    setChanges(true);
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>

        <div className='mt-8'>
          <h3 className='text-2xl font-semibold mb-4 text-gray-800'>Shop Info</h3>
          <table className='min-w-full divide-y-2 divide-gray-200 text-sm'>
            <tbody className='divide-y divide-gray-200'>
              {[
                ['Name', user.shopInfo.name],
                ['Address Line 1', user.shopInfo.address.addressLine1],
                ['Address Line 2', user.shopInfo.address.addressLine2 || 'N/A'],
                ['City', user.shopInfo.address.city],
                ['State', user.shopInfo.address.state],
                ['Pincode', user.shopInfo.address.pincode],
                ['Landmark', user.shopInfo.address.landmark],
                ['Description', user.shopInfo.address.description],
                ['Latitude', user.shopInfo.address.lat],
                ['Longitude', user.shopInfo.address.long],
              ].map(([label, value]) => (
                <tr key={label}>
                  <td className='whitespace-nowrap px-4 py-2 font-medium text-gray-900'>{label}</td>
                  <td className='whitespace-nowrap px-4 py-2 text-gray-700'>{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <button
          className={`mt-8 w-full py-3 rounded-md text-white ${
            changes ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-300 cursor-not-allowed'
          }`}
          onClick={handleSave}
          disabled={!changes}
        >
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default EditShop;
