// import { LockClosedIcon } from "@heroicons/react/20/solid";
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../AuthContext';
import { AuthService } from '../services/auth';
import Cookies from 'js-cookie';
import { UserService } from '../services/user';

function Login() {
  const [form, setForm] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState('');

  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const fetchUserInfo = async () => {
    const data = await UserService.fetchUserInformation();
    if (data) {
      authContext.signin(data, () => {
        navigate('/');
      });
    }
  };

  const loginUser = async (e) => {
    e.preventDefault();
    // Cannot send empty data
    if (form.email === '' || form.password === '') {
      alert('To login user, enter details to proceed...');
    } else {
      const data = await AuthService.login(form);
      Cookies.set('ns_adm_auth_token', data.token);
      fetchUserInfo();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (Cookies.get('ns_adm_auth_token')) {
      fetchUserInfo();
    }
  }, []);

  return (
    <>
      <div className='grid grid-cols-1 sm:grid-cols-2 h-screen  items-center place-items-center'>
        <div className='flex justify-center'>
          <img src={require('../assets/signup.jpg')} alt='' />
        </div>
        <div className='w-full max-w-md space-y-8 p-10 rounded-lg'>
          <div>
            <img
              className='mx-auto h-12 w-auto'
              src={require('../assets/natsary.webp')}
              alt='Your Company'
            />
            <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
              Signin to your account
            </h2>
          </div>
          <form className='mt-8 space-y-6' onSubmit={handleSubmit}>
            {/* <input type="hidden" name="remember" defaultValue="true" /> */}
            <div className='-space-y-px rounded-md shadow-sm'>
              <div>
                <label htmlFor='email-address' className='sr-only'>
                  Email address
                </label>
                <input
                  id='email-address'
                  name='email'
                  type='email'
                  autoComplete='email'
                  required
                  className='relative block w-full rounded-t-md border-0 py-1.5 px-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                  placeholder='Email address'
                  value={form.email}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor='password' className='sr-only'>
                  Password
                </label>
                <input
                  id='password'
                  name='password'
                  type='password'
                  autoComplete='current-password'
                  required
                  className='relative block w-full rounded-b-md border-0 py-1.5 px-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                  placeholder='Password'
                  value={form.password}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className='flex items-center justify-between'>
              <div className='flex items-center'>
                <input
                  id='remember-me'
                  name='remember-me'
                  type='checkbox'
                  className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                />
                <label htmlFor='remember-me' className='ml-2 block text-sm text-gray-900'>
                  Remember me
                </label>
              </div>

              <div className='text-sm'>
                <span className='font-medium text-indigo-600 hover:text-indigo-500'>
                  Forgot your password?
                </span>
              </div>
            </div>

            <div>
              <button
                type='submit'
                className='group relative flex w-full justify-center rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                onClick={loginUser}
              >
                <span className='absolute inset-y-0 left-0 flex items-center pl-3'>
                  {/* <LockClosedIcon
                    className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                    aria-hidden="true"
                  /> */}
                </span>
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
