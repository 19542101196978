import { NSChip } from '@natsary/ns-fusion';
import React, { useState } from 'react';
import AddQuotation from '../components/AddQuotation';
import ViewQuotations from '../components/ViewQuotations';

const Quotations = () => {
  const [step, setStep] = useState(0);

  const getComponent = () => {
    switch (step) {
      case 0:
        return <AddQuotation />;
      case 1:
        return <ViewQuotations />;
      default:
        return null;
    }
  };
  return (
    <div className='p-2 w-full'>
      <div className='flex border-b-2 p-2'>
        <NSChip
          label='Send Quotation'
          onClick={() => setStep(0)}
          active={step === 0 && step !== 1}
        />
        <NSChip label='View Quotations' onClick={() => setStep(1)} active={step === 1} />
      </div>
      {getComponent()}
    </div>
  );
};

export default Quotations;
