const getBGColorFromStatus = (status) => {
  switch (status) {
    case 'success':
      return '#65A74D';
    case 'initiated':
      return '#186faf';
    default:
      return 'black';
  }
};

export { getBGColorFromStatus };
