import React, { useState, useEffect, useContext } from 'react';
import AddStore from '../components/AddStore';
import AuthContext from '../AuthContext';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { NSLoader, NSPagination } from '@natsary/ns-fusion';

function Shops() {
  const [showModal, setShowModal] = useState(false);
  const [stores, setAllStores] = useState([]);
  const [page, setPage] = useState(1);
  const [totalShops, setTotalShops] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAllShops();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // Fetching all stores data
  const fetchAllShops = async () => {
    try {
      const query = `role=vendor&page=${page - 1}`;
      const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;
      
      const response = await fetch(`${BASE_API_URL}/natsary-admin/user?${query}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
        },
      });
      const { data } = await response.json();
      setAllStores(data.users);
      setTotalShops(data.total);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const modalSetting = () => {
    setShowModal(!showModal);
  };

  return (
    <div className='w-full flex justify-center h-screen'>
      <NSLoader isVisible={loading} />
      <div className=' flex flex-col gap-5 w-11/12'>
        <div className='flex justify-between'>
          <span className='font-bold'>Manage Shops</span>
          <button
            className='bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 text-xs  rounded'
            onClick={modalSetting}
          >
            Add Shops
          </button>
        </div>
        <table className='min-w-full divide-y-2 divide-gray-200 text-sm p-6 bg-white rounded-lg shadow-lg'>
          <thead>
            <tr>
              <th className='whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900'>
                Shop Name
              </th>
              <th className='whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-center'>
                User Details
              </th>
              <th className='whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-center'>
                Registered?
              </th>
            </tr>
          </thead>

          <tbody className='divide-y divide-gray-200'>
            {stores.map((store) => {
              return (
                <tr key={store._id}>
                  <td className='whitespace-nowrap px-4 py-2  text-gray-900'>
                    <Link className='text-blue-600 underline' to={`/manage-store/${store._id}`}>
                      {store.shopInfo.name}
                    </Link>
                  </td>
                  <td className='whitespace-nowrap px-4 py-2 text-gray-700 text-center'>
                    {store.firstName} - {store.phone}
                  </td>
                  <td className='whitespace-nowrap px-4 py-2 text-gray-700 text-center'>
                    {store.isUserRegistered ? 'Yes' : 'No'}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <NSPagination
          onPageChange={(activePage) => setPage(activePage)}
          activePage={page}
          totalItems={totalShops}
        />
        {showModal && <AddStore />}
      </div>
    </div>
  );
}

export default Shops;
