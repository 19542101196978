import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import sampleImage from '../assets/natsary.webp';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';

const CustomServices = () => {
  const [enrollments, setEnrollments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCarouselModalOpen, setIsCarouselModalOpen] = useState(false);
  const [carouselImages, setCarouselImages] = useState([]); 

  // Function to fetch custom enrollments
  const fetchCustomEnrollments = async () => {
    try {
      const response = await fetch('http://localhost:7075/natsary-admin/service/custom-enroll', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setEnrollments(data.data);
    } catch (err) {
      console.error('Error fetching custom enrollments:', err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomEnrollments();
  }, []);

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  const openCarouselModal = (images) => {
    setCarouselImages(images);
    setIsCarouselModalOpen(true);
  };

  const closeCarouselModal = () => {
    setIsCarouselModalOpen(false);
  };

  if (loading) return <p>Loading custom enrollments...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const sampleImageArr = [sampleImage, sampleImage, sampleImage, sampleImage, sampleImage];

  return (
    <div className='w-full p-4'>
      <h1 className='text-xl font-semibold'>Custom Enrollments</h1>
      {enrollments.length > 0 ? (
        <ul>
          {enrollments.map((enrollment) => (
            <div className='bg-white p-4 my-2 rounded-lg flex flex-wrap justify-between'>
              <li key={enrollment._id}>
                <p>
                  <strong>Enrollment ID:</strong> {enrollment._id}
                </p>
                <p>
                  <strong>User ID:</strong> {enrollment.userId}
                </p>
                <p>
                  <strong>Descriptions:</strong> {enrollment.description}
                </p>
                <p>
                  <strong>Phone No:</strong> {enrollment.phone}
                </p>
                <p>
                  <strong>Status:</strong> {enrollment.status}
                </p>
              </li>
              <div>
                {enrollment.assetUrl && enrollment.assetUrl.length > 0 && (
                  <div className='mt-4'>
                    <p className='font-semibold'>Images:</p>
                    <div className='grid grid-cols-2 gap-4'>
                      {enrollment.assetUrl.length <= 4 &&
                        enrollment.assetUrl
                          .slice(0, 4)
                          .map((url, index) => (
                            <img
                              key={index}
                              src={url}
                              alt={`Enrollment Asset ${index + 1}`}
                              className='w-20 h-20 rounded-lg cursor-pointer'
                              onClick={() => openModal(url)}
                            />
                          ))}
                      {enrollment.assetUrl.length > 4 && (
                        <>
                          {enrollment.assetUrl.slice(0, 3).map((url, index) => (
                            <img
                              key={index}
                              src={url}
                              alt={`Enrollment Asset ${index + 1}`}
                              className='w-20 h-20 rounded-lg cursor-pointer'
                              onClick={() => openModal(url)}
                            />
                          ))}
                          <div
                            className='w-20 h-20 rounded-lg flex items-center justify-center bg-gray-200 cursor-pointer'
                            onClick={() => openCarouselModal(enrollment.assetUrl)}
                          >
                            <span className='text-gray-600 font-semibold'>
                              +{enrollment.assetUrl.length - 3}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
                {/* {enrollment.assetUrl.length === 0 && (
                  <div className='mt-4'>
                    <p className='font-semibold'>Images:</p>
                    <div className='grid grid-cols-2 gap-4'>
                      {sampleImageArr.length <= 4 &&
                        sampleImageArr
                          .slice(0, 4)
                          .map((image, index) => (
                            <img
                              key={index}
                              src={image}
                              alt={`Sample Image ${index + 1}`}
                              className='w-20 h-20 rounded-lg cursor-pointer'
                              onClick={() => openModal(image)}
                            />
                          ))}
                      {sampleImageArr.length > 4 && (
                        <>
                          {sampleImageArr.slice(0, 3).map((image, index) => (
                            <img
                              key={index}
                              src={image}
                              alt={`Sample Image ${index + 1}`}
                              className='w-20 h-20 rounded-lg cursor-pointer'
                              onClick={() => openModal(image)}
                            />
                          ))}
                          <div
                            className='w-20 h-20 rounded-lg flex items-center justify-center bg-gray-200 cursor-pointer'
                            onClick={() => openCarouselModal()}
                          >
                            <span className='text-gray-600 font-semibold'>
                              +{sampleImageArr.length - 3}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )} */}
                {/* <img
                  src={require('../assets/natsary.webp')}
                  className='w-20 h-20 rounded-lg cursor-pointer'
                  onClick={() => openModal(require('../assets/natsary.webp'))}
                /> */}
              </div>
            </div>
          ))}
        </ul>
      ) : (
        <p>No custom enrollments found.</p>
      )}

      {/* Modal for showing the larger image */}
      {isModalOpen && (
        <div
          className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center'
          onClick={closeModal}
        >
          <div className='bg-white p-4 rounded-lg relative' onClick={(e) => e.stopPropagation()}>
            <img
              src={selectedImage}
              alt='Enlarged view'
              className='w-full h-auto max-w-lg rounded-lg'
            />
            <button
              className='absolute top-2 right-2 text-black font-bold text-lg'
              onClick={closeModal}
            >
              &times;
            </button>
          </div>
        </div>
      )}

      {/* Modal for showing the larger carousel image */}
      {isCarouselModalOpen && (
        <div
          className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center'
          onClick={closeCarouselModal}
        >
          <div className='bg-white p-4 rounded-lg relative' onClick={(e) => e.stopPropagation()}>
          {carouselImages.length > 0 ? (
              <Swiper
                modules={[Navigation, Pagination]}
                navigation
                pagination={{ clickable: true }}
                spaceBetween={30}
                slidesPerView={1}
              >
                {carouselImages.map((url, index) => (
                  <SwiperSlide key={index}>
                    <img src={url} alt={`Enrollment Asset ${index + 1}`} className='w-full h-auto' />
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <p>No images available for carousel</p>
            )}
            <button
              className='absolute top-2 right-2 text-black font-bold text-lg'
              onClick={closeCarouselModal}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomServices;
