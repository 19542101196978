import React, { useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { NSBadge, NSLoader, NSPagination } from '@natsary/ns-fusion';
import { getBGColorFromStatus } from '../utils/util';

const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const [selectedTab, setSelectedTab] = useState('');
  const [page, setPage] = useState(1);
  const [paymentStatus, setPaymentStatus] = useState('PAYMENT_SUCCESS');
  const [orderStatus, setOrderStatus] = useState('placed');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFilteredOrders = async () => {
      try {
        let query = `page=${page}&paymentStatus=${paymentStatus}`;
        if (paymentStatus === 'PAYMENT_SUCCESS') {
          query = query + `&orderStatus=${orderStatus}`;
        }
        if (selectedTab !== '') {
          query = query + `&orderType=${selectedTab}`;
        }
        const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;

        const response = await fetch(`${BASE_API_URL}/natsary-admin/orders?${query}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
          },
        });
        const { data } = await response.json();
        setOrders(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };
    setLoading(true);
    fetchFilteredOrders();
  }, [selectedTab, paymentStatus, orderStatus, page]);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const handlePaymentStatusChange = (status) => {
    setPaymentStatus(status);
  };

  const handleOrderStatusChange = (status) => {
    setOrderStatus(status);
  };

  return (
    <div className='w-full p-4 h-screen'>
      <NSLoader isVisible={loading} />
      <div className='mb-4 overflow-x-auto'>
        <Tab.Group>
          <Tab.List className='flex space-x-4'>
            <Tab
              className={({ selected }) =>
                `px-4 py-2 rounded-md cursor-pointer ${
                  selected
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                } transition-colors duration-300`
              }
              onClick={() => handleTabChange('')}
            >
              All
            </Tab>
            <Tab
              className={({ selected }) =>
                `px-4 py-2 rounded-md cursor-pointer ${
                  selected
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                } transition-colors duration-300`
              }
              onClick={() => handleTabChange('delivery')}
            >
              Delivery
            </Tab>
            <Tab
              className={({ selected }) =>
                `px-4 py-2 rounded-md cursor-pointer ${
                  selected
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                } transition-colors duration-300`
              }
              onClick={() => handleTabChange('service')}
            >
              Service
            </Tab>
          </Tab.List>
        </Tab.Group>
      </div>
      <div className='mb-4 overflow-x-auto'>
        <Tab.Group>
          <Tab.List className='flex space-x-4'>
            <Tab
              className={({ selected }) =>
                `px-4 py-2 rounded-md cursor-pointer ${
                  selected
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                } transition-colors duration-300`
              }
              onClick={() => handlePaymentStatusChange('PAYMENT_SUCCESS')}
            >
              Payment Success
            </Tab>
            <Tab
              className={({ selected }) =>
                `px-4 py-2 rounded-md cursor-pointer ${
                  selected
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                } transition-colors duration-300`
              }
              onClick={() => handlePaymentStatusChange('PAYMENT_INITIATED')}
            >
              Payment Initiated
            </Tab>
            <Tab
              className={({ selected }) =>
                `px-4 py-2 rounded-md cursor-pointer ${
                  selected
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                } transition-colors duration-300`
              }
              onClick={() => handlePaymentStatusChange('PAYMENT_DECLINED')}
            >
              Payment Declined
            </Tab>
          </Tab.List>
        </Tab.Group>
      </div>
      {paymentStatus === 'PAYMENT_SUCCESS' && (
        <div className='mb-4 overflow-x-auto'>
          <Tab.Group>
            <Tab.List className='flex space-x-4'>
              <Tab
                className={({ selected }) =>
                  `px-4 py-2 rounded-md cursor-pointer ${
                    selected ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
                  }`
                }
                onClick={() => handleOrderStatusChange('placed')}
              >
                Order Placed
              </Tab>
              <Tab
                className={({ selected }) =>
                  `px-4 py-2 rounded-md cursor-pointer ${
                    selected ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
                  }`
                }
                onClick={() => handleOrderStatusChange('accepted')}
              >
                Order Accepted
              </Tab>
              <Tab
                className={({ selected }) =>
                  `px-4 py-2 rounded-md cursor-pointer ${
                    selected ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
                  }`
                }
                onClick={() => handleOrderStatusChange('dispatched')}
              >
                Order Dispatched
              </Tab>
              <Tab
                className={({ selected }) =>
                  `px-4 py-2 rounded-md cursor-pointer ${
                    selected ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
                  }`
                }
                onClick={() => handleOrderStatusChange('delivered')}
              >
                Order Delivered
              </Tab>
              <Tab
                className={({ selected }) =>
                  `px-4 py-2 rounded-md cursor-pointer ${
                    selected ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
                  }`
                }
                onClick={() => handleOrderStatusChange('cancelled')}
              >
                Order Cancelled
              </Tab>
              <Tab
                className={({ selected }) =>
                  `px-4 py-2 rounded-md cursor-pointer ${
                    selected ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
                  }`
                }
                onClick={() => handleOrderStatusChange('returned')}
              >
                Order Returned
              </Tab>
              <Tab
                className={({ selected }) =>
                  `px-4 py-2 rounded-md cursor-pointer ${
                    selected ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
                  }`
                }
                onClick={() => handleOrderStatusChange('initiated')}
              >
                Order Initiated
              </Tab>
              {/* Add more order status tabs as needed */}
            </Tab.List>
          </Tab.Group>
        </div>
      )}
      <div className='overflow-x-auto'>
        <table className='min-w-full divide-y-2 divide-gray-200 text-sm p-6 bg-white rounded-lg shadow-lg'>
          <thead>
            <tr>
              <th className='whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900'>
                Order ID
              </th>
              <th className='whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900'>
                User Details
              </th>
              <th className='whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900'>
                Order Type
              </th>
              <th className='whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900'>
                Payment Status
              </th>
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-200'>
            {orders.map((order) => (
              <tr key={order._id}>
                <td className='whitespace-nowrap px-4 py-2 text-gray-900'>
                  <Link className='text-blue-600 underline' to={`/orders/${order._id}`}>
                    {order.publicOrderId}
                  </Link>
                </td>
                <td className='whitespace-nowrap px-4 py-2 text-gray-700'>
                  {order.userId.firstName} - {order.userId.phone}
                </td>
                <td className='whitespace-nowrap px-4 py-2 text-gray-700'>{order.orderType}</td>
                <td className='whitespace-nowrap px-4 py-2 text-gray-700'>
                  <NSBadge
                    text={order.paymentStatus}
                    backgroundColor={getBGColorFromStatus(order.paymentStatus)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderList;
