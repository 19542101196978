import React, { useState, useEffect } from 'react';
// import AddProductType from './AddProductType';
import Cookies from 'js-cookie';
import AddProductType from '../components/AddProductType';
import { Link } from 'react-router-dom';

function ProductTypes() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [productTypes, setProductTypes] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [showProductTypeModal, setShowProductTypeModal] = useState(false);

  useEffect(() => {
    fetchProductTypes();
  }, [page]);

  const fetchProductTypes = async () => {
    const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;
    const response = await fetch(`${BASE_API_URL}/natsary-admin/productType`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
      },
    });
    if (response.ok) {
      const { data } = await response.json();
      setTotalItems(data.total);
      setProductTypes(data.productTypes);
    }
  };

  const addProductTypeModalSetting = () => {
    setShowProductTypeModal(!showProductTypeModal);
  };

  const refreshData = () => {
    fetchProductTypes();
  };

  return (
    <div className='w-full flex justify-center h-screen'>
      <div className='flex flex-col gap-5 w-11/12'>
        <div className='bg-white rounded p-3'>
          <div className='flex justify-between items-center pb-5'>
            <span className='font-semibold px-4'>Product Types</span>
            <button
              className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
              onClick={addProductTypeModalSetting}
            >
              Add Product Type
            </button>
          </div>
          <div className='overflow-x-auto border p-6 bg-white rounded-lg shadow-lg'>
            <table className='min-w-full divide-y-2 divide-gray-200 text-sm'>
              <thead>
                <tr>
                  <th className='whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900'>
                    Name
                  </th>
                  <th className='whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900'>
                    Code
                  </th>
                  <th className='whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900'>
                    Active
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200'>
                {productTypes.map((type) => (
                  <tr key={type._id}>
                    <td className='whitespace-nowrap px-4 py-2 text-gray-900'>
                      <Link className='text-blue-600 underline' to={`/product-types/${type._id}`}>
                        {type.name}
                      </Link>
                    </td>
                    <td className='whitespace-nowrap px-4 py-2 text-gray-700'>{type.code}</td>
                    <td className='whitespace-nowrap px-4 py-2 text-gray-700'>
                      {type.active ? 'Yes' : 'No'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {showProductTypeModal && (
          <AddProductType
            addProductTypeModalSetting={addProductTypeModalSetting}
            refreshData={refreshData}
          />
        )}
      </div>
    </div>
  );
}

export default ProductTypes;
