import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { NSLoader } from '@natsary/ns-fusion';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';

const EditProduct = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [shopInfo, setShopInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [changes, setChanges] = useState(false);
  const [categories, setCategories] = useState([]);
  const [extraData, setExtraData] = useState([]);
  const [imageUploading, setImageUploading] = useState(false);
  const [imagesToUpload, setImagesToUpload] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);

  useEffect(() => {
    fetchProductDetails();
    fetchCategories();
  }, [productId]);

  const fetchProductDetails = async () => {
    try {
      const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;

      const response = await fetch(`${BASE_API_URL}/natsary-admin/product/${productId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
        },
      });
      if (response.ok) {
        const { data } = await response.json();
        setProduct(data.product);
        if (data.product.seller) {
          setShopInfo(data.product.seller.shopInfo);
        }
        if (data.product.extradata) {
          setExtraData(Object.entries(data.product.extradata));
        }
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching product details:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;
      
      const response = await fetch(`${BASE_API_URL}/natsary-admin/productCategory`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
        },
      });
      if (response.ok) {
        const { data } = await response.json();
        const { productCategories } = data;
        setCategories(productCategories);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleSave = async () => {
    setImageUploading(true);
    const region = process.env.REACT_APP_AWS_REGION;
    const s3Client = new S3Client({
      region: region,
      credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      },
    });

    const uploadedImageUrls = [...product.imgUrl];
    for (const image of imagesToUpload) {
      const key = `${product.name.substring(0, 8)}${new Date().toISOString()}.png`;
      const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        Key: key,
        Body: image,
      };
      const command = new PutObjectCommand(params);
      const data = await s3Client.send(command);
      const imageUrl = `https://${params.Bucket}.s3.${region}.amazonaws.com/${key}`;
      uploadedImageUrls.push(imageUrl);
    }

    const modifiedProduct = { ...product, imgUrl: uploadedImageUrls, extradata: Object.fromEntries(extraData) };
    if (product.seller) modifiedProduct.seller = product.seller._id;
    const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;

    try {
      const response = await fetch(
        `${BASE_API_URL}/natsary-admin/product/update/${productId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
          },
          body: JSON.stringify(modifiedProduct),
        },
      );
      if (response.ok) {
        alert('Product details updated successfully!');
        setChanges(false);
      } else {
        alert('Failed to update product details.');
      }
    } catch (error) {
      console.error('Error saving product details:', error);
    } finally {
      setImageUploading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setProduct({ ...product, [name]: newValue });
    setChanges(true);
  };

  const handleExtraDataChange = (index, keyOrValue, newValue) => {
    const updatedExtraData = extraData.map((entry, i) =>
      i === index
        ? [keyOrValue === 'key' ? newValue : entry[0], keyOrValue === 'value' ? newValue : entry[1]]
        : entry,
    );
    setExtraData(updatedExtraData);
    setChanges(true);
  };

  const handleAddExtraData = () => {
    setExtraData([...extraData, ['', '']]);
    setChanges(true);
  };

  const handleDeleteImage = (index) => {
    const updatedImages = product.imgUrl.filter((_, i) => i !== index);
    const updatedPreviewUrls = previewUrls.filter((_, i) => i !== index);
    setProduct({ ...product, imgUrl: updatedImages });
    setPreviewUrls(updatedPreviewUrls);
    setChanges(true);
  };

  const handleAddImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      const newPreviewUrl = URL.createObjectURL(file);
      setImagesToUpload([...imagesToUpload, file]);
      setPreviewUrls([...previewUrls, newPreviewUrl]);
      setChanges(true);
    }
    e.target.value = null; // Reset the input value
  };

  if (loading) {
    return <NSLoader isVisible />;
  }

  return (
    <div className='w-full p-4 bg-gray-100 min-h-screen flex flex-col items-center'>
      <div className='w-full overflow-x-auto p-6 bg-white rounded-lg shadow-lg'>
        <h2 className='text-3xl font-semibold mb-8 text-gray-800'>Product Details</h2>
        <table className='min-w-full divide-y-2 divide-gray-200 text-sm'>
          <tbody className='divide-y divide-gray-200'>
            {[
              ['Name', 'name', product.name],
              ['Category', 'category', product.productCategory],
              ['Quantity', 'quantity', product.quantity],
              ['SKU ID', 'skuId', product.skuId],
              ['Base Price', 'basePrice', product.basePrice],
              ['MRP', 'mrp', product.mrp],
              ['Price', 'price', product.price],
              ['Description', 'description', product.description],
              ['Active', 'active', product.active, 'checkbox'],
              ['In Stock', 'inStock', product.inStock, 'checkbox'],
            ].map(([label, name, value, type]) => (
              <tr key={name}>
                <td className='whitespace-nowrap px-4 py-2 font-medium text-gray-900'>{label}</td>
                <td className='whitespace-nowrap px-4 py-2 text-gray-700'>
                  {name === 'category' ? (
                    <select
                      name={name}
                      value={value}
                      onChange={handleChange}
                      className='w-full border rounded px-2 py-1'
                    >
                      {categories.map((category) => (
                        <option key={category._id} value={category._id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  ) : type === 'checkbox' ? (
                    <input
                      type='checkbox'
                      name={name}
                      checked={value}
                      onChange={handleChange}
                      className='h-6 w-6 text-blue-600'
                    />
                  ) : (
                    <input
                      type='text'
                      name={name}
                      value={value}
                      onChange={handleChange}
                      className='w-full border rounded px-2 py-1'
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className='mt-8'>
          <h3 className='text-2xl font-semibold mb-4 text-gray-800'>Images</h3>
          <div className='space-y-4'>
          <div className='space-y-4'>
            {product.imgUrl.map((url, index) => (
              <div key={index} className='flex items-center space-x-4'>
                <img src={url} alt={`Image ${index + 1}`} className='h-20 w-20 object-cover' />
                <input
                  type='text'
                  value={url}
                  readOnly
                  className='flex-1 border rounded px-2 py-1'
                />
                <button
                  onClick={() => handleDeleteImage(index)}
                  className='py-1 px-3 bg-red-500 text-white rounded'
                >
                  Delete
                </button>
              </div>
            ))}
            {previewUrls.map((url, index) => (
              <div key={product.imgUrl.length + index} className='flex items-center space-x-4'>
                <img src={url} alt={`New Image ${index + 1}`} className='h-20 w-20 object-cover' />
                <input
                  type='text'
                  value={url}
                  readOnly
                  className='flex-1 border rounded px-2 py-1'
                />
                <button
                  onClick={() => handleDeleteImage(product.imgUrl.length + index)}
                  className='py-1 px-3 bg-red-500 text-white rounded'
                >
                  Delete
                </button>
              </div>
            ))}
          </div>
          <div className='mt-4'>
            <input type='file' onChange={handleAddImage} className='border rounded px-2 py-1' />
          </div>
        </div>

        <div className='mt-8'>
          <h3 className='text-2xl font-semibold mb-4 text-gray-800'>Extra Data</h3>
          <table className='min-w-full divide-y-2 divide-gray-200 text-sm'>
            <tbody className='divide-y divide-gray-200'>
              {extraData.map(([key, value], index) => (
                <tr key={index}>
                  <td className='whitespace-nowrap px-4 py-2 font-medium text-gray-900'>
                    <input
                      type='text'
                      value={key}
                      onChange={(e) => handleExtraDataChange(index, 'key', e.target.value)}
                      className='w-full border rounded px-2 py-1'
                      placeholder='Key'
                    />
                  </td>
                  <td className='whitespace-nowrap px-4 py-2 text-gray-700'>
                    <input
                      type='text'
                      value={value}
                      onChange={(e) => handleExtraDataChange(index, 'value', e.target.value)}
                      className='w-full border rounded px-2 py-1'
                      placeholder='Value'
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            onClick={handleAddExtraData}
            className='mt-4 py-2 px-4 bg-blue-600 text-white rounded'
          >
            Add Extra Data
          </button>
        </div>

        {product.seller && (
          <div className='mt-8'>
            <h3 className='text-2xl font-semibold mb-4 text-gray-800'>Shop Info</h3>
            <table className='min-w-full divide-y-2 divide-gray-200 text-sm'>
              <tbody className='divide-y divide-gray-200'>
                {[
                  ['Name', shopInfo.name],
                  ['Address Line 1', shopInfo.address?.addressLine1],
                  ['Address Line 2', shopInfo.address?.addressLine2 || 'N/A'],
                  ['City', shopInfo.address?.city],
                  ['State', shopInfo.address?.state],
                  ['Pincode', shopInfo.address?.pincode],
                  ['Landmark', shopInfo.address?.landmark],
                  ['Description', shopInfo.address?.description],
                  ['Latitude', shopInfo.address?.lat],
                  ['Longitude', shopInfo.address?.long],
                ].map(([label, value]) => (
                  <tr key={label}>
                    <td className='whitespace-nowrap px-4 py-2 font-medium text-gray-900'>
                      {label}
                    </td>
                    <td className='whitespace-nowrap px-4 py-2 text-gray-700'>{value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <div className='mt-8 flex justify-end'>
          <button
            onClick={handleSave}
            className={`py-2 px-4 bg-green-600 text-white rounded ${imageUploading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={imageUploading}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default EditProduct;
