import React from 'react';
import { useLocation } from 'react-router-dom';

const UsersDetails = () => {
  const location = useLocation();
  const { user } = location.state;
  console.log(user);

  const formattedDOB = user.dob 
    ? new Date(user.dob).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) 
    : 'N/A';

  return (
    <div className="w-full h-screen">
      <div className='font-semibold text-2xl my-2'>User Details</div>
      <p>
        <strong>User ID:</strong> {user._id}
      </p>
      <p>
        <strong>Firstname:</strong> {user.firstName}
      </p>
      <p>
        <strong>Lastname:</strong> {user.lastname}
      </p>
      <p>
        <strong>Gender:</strong> {user.gender}
      </p>
      <p>
        <strong>Date of birth:</strong> {formattedDOB}
      </p>
      <p>
        <strong>Email:</strong> {user.email}
      </p>
      <p>
        <strong>Phone No:</strong> {user.phone}
      </p>
      <p>
        <strong>Product Interest:</strong> {user.productInterest?.join(', ') || 'N/A'}
      </p>
      <p>
        <strong>Region:</strong> {user.region}
      </p>
      <p>
        <strong>Role:</strong> {user.role}
      </p>
      <p>
        <strong>Wallet Amount:</strong> {user.walletAmount}
      </p>
    </div>
  );
};

export default UsersDetails;
