import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { UserService } from '../services/user';
import { NSPagination } from '@natsary/ns-fusion';

function Users() {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const fetchUsers = async () => {
    const { users, total } = await UserService.fetchAllUsers(page - 1);
    setUsers(users);
    setTotal(total);
  };

  const refreshData = (pageNumber) => {
    setPage(pageNumber);
    fetchUsers();
  };

  return (
    <div className='w-full flex justify-center h-screen'>
      <div className='flex flex-col gap-5 w-11/12'>
        <div className=''>
          <div className='flex justify-between items-center pb-5'>
            <span className='font-semibold px-4'>Users</span>
          </div>
          <div className='overflow-x-auto border p-6 bg-white rounded-lg'>
            <table className='min-w-full divide-y-2 divide-gray-200 text-sm'>
              <thead>
                <tr>
                  <th className='whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900'>
                    Name
                  </th>
                  <th className='whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900'>
                    User ID
                  </th>
                  <th className='whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900'>
                    Active
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200'>
                {users.map((user) => (
                  <tr key={user._id}>
                    <td className='whitespace-nowrap px-4 py-2 text-gray-900'>
                      <Link
                        className='text-blue-600 underline'
                        to={`/users/${user._id}`}
                        state={{ user }}
                      >
                        {`${user.firstName} ${user.lastName}`} ({user.phone || user.email})
                      </Link>
                    </td>
                    <td className='whitespace-nowrap px-4 py-2 text-gray-700'>{user._id}</td>
                    <td className='whitespace-nowrap px-4 py-2 text-gray-700'>
                      {user.active ? 'Yes' : 'No'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <NSPagination
          totalItems={total}
          itemsPerPage={10}
          activePage={1}
          onPageChange={(pageNumber) => refreshData(pageNumber)}
        />
      </div>
    </div>
  );
}

export default Users;
