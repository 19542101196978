import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Cookies from 'js-cookie';

export default function AddProductType({ addProductTypeModalSetting, refreshData }) {
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(true);
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [description, setDescription] = useState('');
  const [active, setActive] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;

    const response = await fetch(`${BASE_API_URL}/natsary-admin/productType/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
      },
      body: JSON.stringify({ name, code, description, active }),
    });
    if (response.ok) {
      refreshData();
      setOpen(false);
      addProductTypeModalSetting();
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg'>
                <div className='p-6 bg-white rounded-lg shadow-lg'>
                  <h2 className='text-xl font-bold mb-4'>Add Product Type</h2>
                  <form onSubmit={handleSubmit}>
                    <div className='mb-4'>
                      <label className='block text-gray-700'>Name</label>
                      <input
                        type='text'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className='w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500'
                        required
                      />
                    </div>
                    <div className='mb-4'>
                      <label className='block text-gray-700'>Code</label>
                      <input
                        type='text'
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        className='w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500'
                        required
                      />
                    </div>
                    <div className='mb-4'>
                      <label className='block text-gray-700'>Description</label>
                      <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className='w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500'
                      />
                    </div>
                    <div className='mb-4 flex items-center'>
                      <input
                        type='checkbox'
                        checked={active}
                        onChange={(e) => setActive(e.target.checked)}
                        className='mr-2 leading-tight'
                      />
                      <label className='text-gray-700'>Active</label>
                    </div>
                    <div className='bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
                      <button
                        type='submit'
                        className='inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto'
                      >
                        Submit
                      </button>
                      <button
                        type='button'
                        className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto'
                        onClick={() => {
                          addProductTypeModalSetting();
                          setOpen(false);
                        }}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
