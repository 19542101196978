import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import SideMenu from './SideMenu';

function Layout() {
  return (
    <>
      <div className='fixed top-0 left-0 right-0'>
        <Header />
      </div>
      <div className='flex pt-16 bg-gray-100'>
        <div className='w-64 fixed top-16 left-0 bottom-0 hidden lg:flex'>
          <SideMenu />
        </div>
        <div className='flex-grow lg:ml-64 p-4'>
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default Layout;
