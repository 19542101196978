import Cookies from 'js-cookie';
const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;
const QUOTATION_URL = `${BASE_API_URL}/natsary-admin/quotation`;
export const QuotationService = {
  fetchAllQuotations: async () => {
    try {
      const response = await fetch(QUOTATION_URL, {
        headers: {
          Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
        },
      });

      if (response.ok) {
        const { data } = await response.json();
        return data;
      } else {
        const { message } = await response.json();
        throw new Error(message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  },

  sendQuotation: async (formData) => {
    try {
      const response = await fetch(`${QUOTATION_URL}/send`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
        },
      });
      if (response.ok) {
        const { data } = await response.json();
        return data;
      } else {
        const { message } = await response.json();
        throw new Error(message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  },
};
