import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Cookies from 'js-cookie';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { NSInput, NSTextArea, NSTypography } from '@natsary/ns-fusion';

export default function AddProduct({
  addProductModalSetting,
  addServiceModalSetting,
  handlePageUpdate,
  type,
  refreshData,
}) {
  const [productName, setProductName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [basePrice, setBasePrice] = useState('');
  const [pricePerSession, setPricePerSession] = useState('');
  const [stockQuantity, setStockQuantity] = useState(500);
  const [category, setCategory] = useState('');
  const [active, setActive] = useState(true);
  const [inStock, setInStock] = useState(true);
  const [allowCustomService, setAllowCustomService] = useState(false);
  const [categories, setCategories] = useState([]);
  const [images, setImages] = useState([]);
  const [extraData, setExtraData] = useState([]);
  const [servicePackages, setServicePackages] = useState([
    { sessionCount: '', days: '', totalPrice: '' },
  ]);
  const [mrp, setMrp] = useState('');
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const [sellerOptions, setSellerOptions] = useState([]);
  const [selectedSeller, setSelectedSeller] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;

      try {
        const response = await fetch(`${BASE_API_URL}/natsary-admin/productCategory`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
          },
        });
        if (response.ok) {
          const { data } = await response.json();
          const { productCategories } = data;
          const targetCategories = productCategories.filter(productCategory => {
            if (type === 'service') {
              return productCategory.code === "preset_service_package" || productCategory.code === "custom_service_package"
            }
            return productCategory.code !== "preset_service_package" && productCategory.code !== "custom_service_package"
          })
          setCategories(targetCategories);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    const fetchSellers = async () => {
      try {
        const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;

        const response = await fetch(`${BASE_API_URL}/natsary-admin/user?role=vendor`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
          },
        });
        if (response.ok) {
          const { data } = await response.json();
          setSellerOptions(data.users);
        }
      } catch (error) {
        console.error('Error fetching sellers:', error);
      }
    };

    fetchSellers();
    fetchCategories();
  }, []);

  const handleServicePackageChange = (index, field, value) => {
    const updatedPackages = [...servicePackages];
    updatedPackages[index][field] = value;
    setServicePackages(updatedPackages);
  };

  const addServicePackage = () => {
    setServicePackages([...servicePackages, { sessionCount: '', days: '', totalPrice: '' }]);
  };

  const handleExtraDataChange = (index, keyOrValue, newValue) => {
    const updatedExtraData = extraData.map((entry, i) =>
      i === index
        ? [keyOrValue === 'key' ? newValue : entry[0], keyOrValue === 'value' ? newValue : entry[1]]
        : entry,
    );
    setExtraData(updatedExtraData);
  };

  const handleAddExtraData = (e) => {
    e.preventDefault();
    setExtraData([...extraData, ['', '']]);
  };

  const handleRemoveExtraData = (index) => {
    setExtraData(extraData.filter((_, i) => i !== index));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const uploadImagesToAWS = async (images) => {
      const region = process.env.REACT_APP_AWS_REGION;
      const bucketName = process.env.REACT_APP_AWS_BUCKET;

      console.log(region, bucketName);

      const s3Client = new S3Client({
        region: region,
        credentials: {
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        },
      });

      const uploadedImageUrls = [];

      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        const key = `products/${productName.substring(0, 8)}${new Date().toISOString()}.png`; // Generate a unique key for each image

        const params = {
          Bucket: bucketName,
          Key: key,
          Body: image,
        };

        try {
          const command = new PutObjectCommand(params);
          await s3Client.send(command);
  
          // Construct the image URL
          const imageUrl = `https://${bucketName}.s3.${region}.amazonaws.com/${key}`;
          uploadedImageUrls.push(imageUrl);
        } catch(error) {
          console.log(error)
        }


      }

      return uploadedImageUrls;
    };

    const imgUrls = await uploadImagesToAWS(images);

    const productData = {
      name: productName,
      description,
      price,
      basePrice: type !== 'service' ? basePrice : undefined,
      pricePerSession,
      stockQuantity,
      category,
      active,
      inStock,
      allowCustomService,
      mrp,
      imgUrls,
      extraData: Object.fromEntries(extraData),
      servicePackages,
      selectedSeller,
    };

    let body = {};
    if (type === 'product') {
      body = {
        name: productData.name,
        category: productData.category,
        quantity: productData.stockQuantity,
        seller: productData.selectedSeller,
        price: productData.price,
        mrp: productData.mrp,
        imgUrl: productData.imgUrls,
        active: productData.active,
        inStock: productData.inStock,
        basePrice: productData.basePrice,
        extradata: productData.extraData,
        description: productData.description
      };
    } else {
      body = {
        name: productData.name,
        category: productData.category,
        quantity: productData.stockQuantity,
        seller: productData.selectedSeller,
        price: productData.price,
        mrp: productData.mrp,
        imgUrl: productData.imgUrls,
        active: productData.active,
        inStock: productData.inStock,
        allowCustomService: productData.allowCustomService,
        servicePackages: productData.servicePackages,
        pricePerSession: productData.pricePerSession,
        extradata: productData.extraData,
        description: productData.description
      };
    }
    
    try {
      const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;

      const response = await fetch(`${BASE_API_URL}/natsary-admin/product/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
        },
        body: JSON.stringify([body]),
      });
      if (response.ok) {
        type === 'product' ? addProductModalSetting() : addServiceModalSetting();
        refreshData();
      }
      setProductName('');
      setDescription('');
      setPrice('');
      setBasePrice('');
      setPricePerSession('');
      setStockQuantity('');
      setCategory('');
      setActive(false);
      setInStock(false);
      setAllowCustomService(false);
      setMrp('');
      setImages([]);
      setExtraData([]);
      setServicePackages([{ sessionCount: '', days: '', totalPrice: '' }]);
    } catch (error) {
      console.error('Error uploading product:', error);
    }
    
  };

  const handleImageChange = (event) => {
    setImages([...images, ...event.target.files]);
  };

  const handleImageDelete = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>
  
        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                <div>
                  <div className='mt-3 text-center sm:mt-5'>
                    <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
                      Add New {type === 'product' ? 'Product' : 'Service'}
                    </Dialog.Title>
                    <div className='mt-2'>
                      <form onSubmit={handleSubmit}>
                        <div className='overflow-hidden sm:rounded-md'>
                          <div className='bg-white px-4 py-5 sm:p-6'>
                            <div className='grid grid-cols-6 gap-6'>
                              <div className='col-span-6'>
                                <NSInput 
                                type='text'
                                name='product-name'
                                id='product-name'
                                value={productName}
                                onChange={(e) => setProductName(e.target.value)}
                                autoComplete='given-name' 
                                label={`${type === 'product' ? 'Product' : 'Service'} Name`}
                                />
                                <label htmlFor='product-name' className='block text-sm font-medium text-gray-700'>
                                  
                                </label>
   
                              </div>
  
                              <div className='col-span-6'>
                                <NSTextArea
                                  label='Description'
                                  name='description'
                                  id='description'
                                  value={description}
                                  onChange={(e) => setDescription(e.target.value)}
                                  autoComplete='description'
                                />
                              </div>
  
                              <div className='col-span-6 sm:col-span-2'>
                                <NSInput
                                  label='Price'
                                  type='number'
                                  name='price'
                                  id='price'
                                  value={price}
                                  onChange={(e) => setPrice(e.target.value)}
                                  autoComplete='price'
                                />
                              </div>
  
                              {type === 'product' && (
                                <div className='col-span-6 sm:col-span-2'>
                                  <NSInput
                                    label='Base Price'
                                    type='number'
                                    name='base-price'
                                    id='base-price'
                                    value={basePrice}
                                    onChange={(e) => setBasePrice(e.target.value)}
                                    autoComplete='base-price'
                                  />
                                </div>
                              )}
  
                              {type === 'service' && (
                                <div className='col-span-6 sm:col-span-2'>
                                  <NSInput
                                    label='Price Per Session'
                                    type='number'
                                    name='price-per-session'
                                    id='price-per-session'
                                    value={pricePerSession}
                                    onChange={(e) => setPricePerSession(e.target.value)}
                                    autoComplete='price-per-session'
                                  />
                                </div>
                              )}
  
                              <div className='col-span-6 sm:col-span-2'>
                                <NSInput
                                  label='MRP'
                                  type='number'
                                  name='mrp'
                                  id='mrp'
                                  value={mrp}
                                  onChange={(e) => setMrp(e.target.value)}
                                  autoComplete='mrp'
                                />
                              </div>
  
                              <div className='col-span-6'>
                                <label htmlFor='category' className='block text-sm font-medium text-gray-700'>
                                  Category
                                </label>
                                <select
                                  name='category'
                                  id='category'
                                  value={category}
                                  onChange={(e) => setCategory(e.target.value)}
                                  autoComplete='category'
                                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                >
                                  <option value='' disabled>Select category</option>
                                  {categories.map((cat) => (
                                    <option key={cat._id} value={cat._id}>
                                      {cat.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
  
                              <div className='col-span-6'>
                                <label htmlFor='seller' className='block text-sm font-medium text-gray-700'>
                                  Seller
                                </label>
                                <select
                                  name='seller'
                                  id='seller'
                                  value={selectedSeller}
                                  onChange={(e) => setSelectedSeller(e.target.value)}
                                  autoComplete='seller'
                                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                >
                                  <option value='' disabled>Select seller</option>
                                  {sellerOptions.map((seller) => (
                                    <option key={seller._id} value={seller._id}>
                                      {seller.shopInfo.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
  
                              <div className='col-span-6'>
                                <label htmlFor='images' className='block text-sm font-medium text-gray-700'>
                                  Images
                                </label>
                                <div className='mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'>
                                  <div className='space-y-1 text-center'>
                                    <div className='flex justify-center space-x-4'>
                                      {images.map((image, index) => (
                                        <div key={index} className='relative'>
                                          <img
                                            src={URL.createObjectURL(image)}
                                            alt={`Product preview ${index}`}
                                            className='h-24 w-auto object-contain'
                                          />
                                          <button
                                            type='button'
                                            onClick={() => handleImageDelete(index)}
                                            className='absolute top-0 right-0 p-1 bg-red-500 rounded-full text-white'
                                          >
                                            <svg
                                              xmlns='http://www.w3.org/2000/svg'
                                              className='h-4 w-4'
                                              fill='none'
                                              viewBox='0 0 24 24'
                                              stroke='currentColor'
                                            >
                                              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M6 18L18 6M6 6l12 12' />
                                            </svg>
                                          </button>
                                        </div>
                                      ))}
                                    </div>
                                    <div className='flex justify-center text-sm text-gray-600'>
                                      <label
                                        htmlFor='file-upload'
                                        className='relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                                      >
                                        <span>Upload a file</span>
                                        <input
                                          id='file-upload'
                                          name='file-upload'
                                          type='file'
                                          className='sr-only'
                                          onChange={handleImageChange}
                                          multiple
                                        />
                                      </label>
                                      <p className='pl-1'>or drag and drop</p>
                                    </div>
                                    <p className='text-xs text-gray-500'>PNG, JPG, GIF up to 10MB</p>
                                  </div>
                                </div>

                              </div>
                              {type !== 'service' &&
                              <div className='col-span-6 sm:col-span-2'>
                                <NSInput
                                  label='Stock Quantity'
                                  type='number'
                                  name='stock-quantity'
                                  id='stock-quantity'
                                  value={stockQuantity}
                                  onChange={(e) => setStockQuantity(e.target.value)}
                                  autoComplete='stock-quantity'
                                />
                                </div>
                                }
           
                              <div className='col-span-6 sm:col-span-2'>
                                <div className='flex items-center h-5'>
                                  <input
                                    type='checkbox'
                                    name='active'
                                    id='active'
                                    checked={active}
                                    onChange={(e) => setActive(e.target.checked)}
                                    className='h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500'
                                  />
                                  <label htmlFor='active' className='ml-2 block text-sm text-gray-900'>
                                    Active
                                  </label>
                                </div>
                              </div>
                                
                               {type !== 'service' &&
                              <div className='col-span-6 sm:col-span-2'>
                                <div className='flex items-center h-5'>
                                <input
                                  type='checkbox'
                                  name='inStock'
                                  id='inStock'
                                  checked={inStock}
                                  onChange={(e) => setInStock(e.target.checked)}
                                  className='h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500'
                                />
                                <label htmlFor='inStock' className='ml-2 block text-sm text-gray-900'>
                                  In Stock
                                </label>
                              </div>
                            </div> }

                            {type === 'service' && (
                              <div className='col-span-4 sm:col-span-4'>
                                <div className='flex items-center h-5'>
                                  <input
                                    type='checkbox'
                                    name='allowCustomService'
                                    id='allowCustomService'
                                    checked={allowCustomService}
                                    onChange={(e) => setAllowCustomService(e.target.checked)}
                                    className='h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500'
                                  />
                                  <label htmlFor='allowCustomService' className='ml-2 block text-sm text-gray-900'>
                                    Allow Custom Service
                                  </label>
                                </div>
                              </div>
                            )}

                            {type === 'service' && (
                              <div className='col-span-6'>
                                <label className='block text-sm font-medium text-gray-700'>Service Packages</label>
                                {servicePackages.map((packageData, index) => (
                                  <div key={index} className='mt-1 flex flex-col p-2 border rounded-md border-gray-300'>
                                    <NSTypography text={`Package ${index + 1}`} />
                                    <NSInput
                                      label='Session Count'
                                      type='number'
                                      value={packageData.sessionCount}
                                      onChange={(e) => handleServicePackageChange(index, 'sessionCount', e.target.value)}
                                    />
                                    <NSInput
                                      label='Days (30 / 60 / 90 / ...)'
                                      type='number'
                                      value={packageData.days}
                                      onChange={(e) => handleServicePackageChange(index, 'days', e.target.value)}
                                    />
                                    <NSInput
                                      type='number'
                                      label='Total Price'
                                      value={packageData.totalPrice}
                                      onChange={(e) => handleServicePackageChange(index, 'totalPrice', e.target.value)}
                                    />
                                  </div>
                                ))}
                                <button
                                  type='button'
                                  onClick={addServicePackage}
                                  className='mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                >
                                  Add Package
                                </button>
                              </div>
                            )}

                            <div className='col-span-6'>
                              <label className='block text-sm font-medium text-gray-700'>Extra Data</label>
                              {extraData.map((entry, index) => (
                                <div key={index} className='flex gap-2 mt-2'>
                                  <input
                                    type='text'
                                    placeholder='Key'
                                    value={entry[0]}
                                    onChange={(e) => handleExtraDataChange(index, 'key', e.target.value)}
                                    className='block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                  />
                                  <input
                                    type='text'
                                    placeholder='Value'
                                    value={entry[1]}
                                    onChange={(e) => handleExtraDataChange(index, 'value', e.target.value)}
                                    className='block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                  />
                                  <button
                                    type='button'
                                    onClick={() => handleRemoveExtraData(index)}
                                    className='bg-red-500 inline-flex items-center px-2 py-1 text-white border border-transparent text-sm font-medium rounded-md bg-red-600 shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                                  >
                                    Remove
                                  </button>
                                </div>
                              ))}
                              <button
                                type='button'
                                onClick={handleAddExtraData}
                                className='mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                              >
                                Add Extra Data
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className='bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
                      <button
                        type='submit'
                        className='inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto'
                      >
                        Submit
                      </button>
                      <button
                        type='button'
                        className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto'
                        onClick={() => type === 'product' ? addProductModalSetting() : addServiceModalSetting()}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
);
}
