import React, { useState, useEffect } from 'react';
import { NSLoader, NSPagination } from '@natsary/ns-fusion';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';

function Queries() {
  const [queries, setQueries] = useState([]);
  const [page, setPage] = useState(1);
  const [totalQueries, setTotalQueries] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAllQueries();
  }, [page]);

  const fetchAllQueries = async () => {
    const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;

    try {
      const query = `page=${page - 1}`;
      const response = await fetch(`${BASE_API_URL}/natsary-admin/user/help?${query}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
        },
      });
      const { data } = await response.json();
      setQueries(data.helpRequests);
      setTotalQueries(data.total);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching help requests:', error);
    }
  };

  const handleResolvedChange = async (queryId, isResolved) => {
    try {
      const BASE_API_URL = process.env.REACT_APP_BACKEND_SERVER;

      const response = await fetch(`${BASE_API_URL}/natsary-admin/user/help/${queryId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('ns_adm_auth_token')}`,
        },
        body: JSON.stringify({ resolved: isResolved }),
      });

      if (response.ok) {
        setQueries((prevQueries) =>
          prevQueries.map((query) =>
            query._id === queryId ? { ...query, resolved: isResolved } : query
          )
        );
      } else {
        console.error('Failed to update the help request');
      }
    } catch (error) {
      console.error('Error updating help request:', error);
    }
  };

  return (
    <div className='w-full flex justify-center h-screen'>
      <NSLoader isVisible={loading} />
      <div className=' flex flex-col gap-5 w-11/12'>
        <div className='flex justify-between'>
          <span className='font-bold'>Manage Help Requests</span>
        </div>
        <table className='min-w-full divide-y-2 divide-gray-200 text-sm p-6 bg-white rounded-lg shadow-lg'>
          <thead>
            <tr>
              <th className='whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900'>
                Name
              </th>
              <th className='whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-center'>
                Message
              </th>
              <th className='whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-center'>
                Resolved
              </th>
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-200'>
            {queries.map((query) => (
              <tr key={query._id}>
                <td className='whitespace-nowrap px-4 py-2  text-gray-900'>
                   {query.userId ? <Link
                   className='text-blue-600 underline'
                   to={`/users/${query.userId}`} >{query.name} ({query.contact})</Link> : `${query.name} ${(query.contact)}`} 
                </td>
                <td className='whitespace-nowrap px-4 py-2 text-gray-700 text-center'>
                  {query.message}
                </td>
                <td className='whitespace-nowrap px-4 py-2 text-gray-700 text-center'>
                  <input
                    type='checkbox'
                    checked={query.resolved}
                    onChange={(e) =>
                      handleResolvedChange(query._id, e.target.checked)
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <NSPagination
          onPageChange={(activePage) => setPage(activePage)}
          activePage={page}
          totalItems={totalQueries}
          itemsPerPage={10}
        />
      </div>
    </div>
  );
}

export default Queries;
